import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import {
    Table,
    Card,
    Space,
    Row,
    Col,
    Tag,
    message,
    Typography,
    Breadcrumb,
    Divider,
    Avatar,
} from "antd";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged, getAuthToken, destroyAuthToken } from "../../helpers/account";
import { api } from "../../config/api";
import { useSelector } from "react-redux";
import { HomeOutlined } from "@ant-design/icons";
import AvatarDisplay from "../../components/Dashboard/Account/AvatarDisplay";
import NewAssetForm from "../Asset/Forms/NewAssetForm";

const { Title, Text } = Typography;

// Table column definitions
const columns = [
    {
        title: "Nazwa aktywa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <Space direction="vertical">
                <Title level={5} style={{ margin: 0 }}>
                    <u style={{ cursor: "pointer" }}>{name}</u>
                </Title>
                <Space>
                    <AvatarDisplay
                        src={record.account?.photo}
                        account={record.account}
                        size={32}
                    />
                    <Tag>{record.account?.company_name}</Tag>
                </Space>
            </Space>
        ),
    },
    {
        title: "Cena",
        dataIndex: "price",
        key: "price",
        sorter: true,
        render: (price) => (
            <Tag color="gold-inverse">{formatPolishCurrency(price)}</Tag>
        ),
    }
];

function AssetsPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const assetsDataChanged = useSelector((state) => state.assetsDataChanged);

    useEffect(() => {
        const apiUrl = `${api.host}/assets`; // API endpoint for assets

        if (sessionStorage.getItem("authToken")) {
            // Fetch data from the API
            fetch(apiUrl, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data); // Update the state with the fetched data
                    setLoading(false); // Set loading to false
                })
                .catch((error) => {
                    setTimeout(() => {
                        message.error("Dla bezpieczeństwa zostałeś wylogowany.");
                        destroyAuthToken();
                        window.location.href = "/assets";
                    }, 2000);
                    setLoading(false); // Set loading to false even on error
                });
        }
    }, [assetsDataChanged]);

    if (isLogged()) {
        return (
            <>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">
                            <Title level={2}>Moje aktywa</Title>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Moje aktywa",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}></Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <NewAssetForm />
                            <Card size="small">
                                <Table
                                    onRow={(record) => ({
                                        onClick: () => {
                                            window.location.href = `/asset/${record.uuid}`;
                                        },
                                    })}
                                    className="shadow"
                                    columns={columns}
                                    dataSource={data}
                                    loading={loading}
                                    locale={{
                                        emptyText: "Nie masz jeszcze żadnych aktywów.",
                                    }}
                                />
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AssetsPage;
