import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
  Card,
  Space,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Divider,
  Descriptions,
  Alert,
  Spin,
  Button,
  Tag
} from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged } from "../../helpers/account";
import { Helmet } from "react-helmet-async";
import {
  CalendarTwoTone,
  HomeOutlined,
  EuroCircleTwoTone,
  ClockCircleTwoTone,
  LockTwoTone,
} from "@ant-design/icons";
import { formatPolishDate } from "../../helpers/date";
import { Inquiries } from "../Offer/InquiriesPage";
import NewInquiryResponseForm from "../Inquiry/Forms/NewInquiryResponseForm";
import MarketplaceInquiryResponsesList from "./MarketplaceInquiryResponsesList";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title } = Typography;

const MarketplaceInquiryPage = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const { inquiryUuid } = useParams();
  const navigate = useNavigate(); // For navigation on button click

  const fetchProductData = async () => {
    try {
      const productResponse = await fetch(
        `${api.host}/inquiries/${inquiryUuid}/public`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      );
      const productData = await productResponse.json();
      setProduct(productData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [inquiryUuid]);

  return (
    <>
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loading}>
          <Row>
            <Col md={4}></Col>
            <Col md={16} style={{ padding: "15px 15px" }}>
              <Space direction="vertical">
                <Title level={2}>{product.name}</Title>

                <Breadcrumb
                  items={[
                    {
                      href: "/",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/marketplace/inquiries",
                      title: "Katalog zapytań ofertowych",
                    },
                    {
                      title: product.name,
                    },
                  ]}
                />
                <img src="/img/svg/17.svg" />
              </Space>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Divider />
          <Row>
            <Col md={4}></Col>
            <Col md={16} style={{ padding: "15px 15px" }}>
              <Row gutter={12}>
                <Col md={12}>
                  <Space size={30} direction="vertical" style={{ width: "100%" }}>
                    <Space>
                      {product.account ? <><AvatarDisplay src={product.account.photo} size={32} account={product.account} />
                        <Space size={1} direction="vertical">
                          {product.account.fullname}
                          <div style={{ fontSize: "75%", color: "rgba(0, 0, 0, 0.45)" }}>Zleceniodawca</div>
                        </Space> <Tag>{product.account.company_name}</Tag></> : ""}
                    </Space>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.message
                          ? product.message.replace(/\n/g, "<br>")
                          : "",
                      }}
                    ></p>

                    {!isLogged() ? (
                      <>
                        <Space direction="horizontal">
                          <Button
                            type="primary"
                            onClick={() => navigate("/transactions")}
                          >
                            Złóż ofertę
                          </Button>
                          <img src="/img/svg/20.svg" style={{ width: "100%", maxWidth: "430px" }} />




                        </Space>
                      </>
                    ) : (
                      <NewInquiryResponseForm inquiryUuid={product.uuid} />
                    )}
                    <Descriptions layout="vertical" bordered>
                      <Descriptions.Item label="Budżet">
                        <EuroCircleTwoTone /> <Tag color="gold-inverse">{formatPolishCurrency(product.budget)}</Tag>
                      </Descriptions.Item>
                      <Descriptions.Item label="Akceptowalny czas realizacji w dniach roboczych">
                        <ClockCircleTwoTone /> {product.delivery_days}
                      </Descriptions.Item>
                      <Descriptions.Item label="Akceptowalny termin realizacji">
                        <CalendarTwoTone /> {formatPolishDate(product.valid_to)}
                      </Descriptions.Item>
                    </Descriptions>


                  </Space>
                </Col>
                <Col md={12}>
                  <center><img src="/img/21.png" style={{ width: "100%" }} /></center>
                </Col>
              </Row>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={4}></Col>
            <Col md={16} style={{ padding: "15px 15px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>

                <Card>
                  <MarketplaceInquiryResponsesList inquiryUuid={product.uuid} />
                </Card>
              </Space>

            </Col>
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={4}></Col>
            <Col md={16} style={{ padding: "15px 15px" }}>
              <Space direction="vertical">
                <Title level={4}>Ostatnio dodane zapytania ofertowe</Title>
                <Inquiries limit={15} />
              </Space>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Spin>
      </Space>
    </>
  );
};

export default MarketplaceInquiryPage;
