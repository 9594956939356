import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  DatePicker,
  Divider,
  Modal,
  Input,
  Select,
  Steps,
  message,
  Alert,
  Spin,
  Collapse,
  Card,
} from "antd";
import {
  isLogged,
  getAuthToken,
  isTransactionInitiatedByLoggedAccount,
  getAccountTransactionRoleId,
} from "../../../helpers/account";
import { api } from "../../../config/api";
import NewBankForm from "../../../components/Dashboard/Account/Bank/Forms/NewBankForm";
import { useDispatch, useSelector } from "react-redux";
import { deliveryTypes } from "../../../data/delivery_types";
import { agreementTypes } from "../../../data/agreement_types";

const { TextArea } = Input;

const EditTransactionForm = (props) => {
  const [editTransactionModalOpen, setEditTransactionModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [placeholders, setPlaceholders] = useState([]);
  const [counterpartyUuid, setCounterpartyUuid] = useState(
    props.transaction.transaction_counterparty.uuid
  );
  const dispatch = useDispatch();
  const bankAccountsListUpdated = useSelector(
    (state) => state.bankAccountsListUpdated
  );
  const [formData, setFormData] = useState({
    title: props.transaction.title,
    agreementText: props.transaction.agreement,
    initiator_bank_id: props.transaction.initiator_bank_id,
    counterparty_bank_id: props.transaction.counterparty_bank_id,
    escrow_fee_paid_by_role_id: getAccountTransactionRoleId(
      props.transaction,
      props.transaction.escrow_fee_payer
    ),
    shipping_fee_paid_by_role_id: getAccountTransactionRoleId(
      props.transaction,
      props.transaction.shipping_fee_payer
    ),
    deliveryCost: props.transaction.shipping_method_fee,
    deliveryMethod: props.transaction.shipping_method_id,
    returnMethod: props.transaction.inspection_items_refused_shipping_method_id,
    shipping_fee_paid_by_account_uuid:
      props.transaction.shipping_fee_payer.uuid,
    escrow_fee_paid_by_account_uuid: props.transaction.escrow_fee_payer.uuid,
    deliveryDate: props.transaction.agreement_items_delivery_at.split(" ")[0],
    inspectionDuration: props.transaction.inspection_period,
    discountCode: props.transaction.discount_code,
  });

  const transactionRoles = [
    {
      value: 2,
      label: "Nabywca",
    },
    {
      value: 1,
      label: "Sprzedawca",
    },
  ];

  useEffect(() => {
    const apiUrl = `${api.host}/bank`;

    if (editTransactionModalOpen && isLogged()) {
      // Fetch bank account data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Map the data to an array of objects with 'value' and 'label' properties
          const mappedBankAccounts = data.map((bank) => ({
            value: bank.id,
            label: bank.name,
          }));

          // Update the 'bankAccounts' state with the mapped data
          setBankAccounts(mappedBankAccounts);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bank account data:", error);
          setLoading(false);
        });
    }
  }, [
    editTransactionModalOpen,
    isLogged,
    getAuthToken,
    bankAccountsListUpdated,
  ]);

  useEffect(() => {
    const text = formData.agreementText;
    const regex = /\[(.*?)\]/g; // Regular expression to match placeholders in square brackets.

    let matchedPlaceholders = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matchedPlaceholders.push(match[1]);
    }

    setPlaceholders(matchedPlaceholders);
  }, [formData.agreementText]);

  const handleUpdateTransaction = () => {
    if (validateFormData()) {
      let apiData = {
        shipping_method_id: formData.deliveryMethod,
        shipping_method_fee: formData.deliveryCost,
        shipping_fee_paid_by_account_uuid:
          props.transaction.initiator_transaction_role_id ==
          formData.shipping_fee_paid_by_role_id
            ? sessionStorage.getItem("uuid")
            : counterpartyUuid,
        inspection_items_refused_shipping_method_id: formData.returnMethod,
        agreement: formData.agreementText,
        title: formData.title,
        inspection_period: formData.inspectionDuration,
        escrow_fee_paid_by_account_uuid:
          props.transaction.initiator_transaction_role_id ==
          formData.escrow_fee_paid_by_role_id
            ? sessionStorage.getItem("uuid")
            : counterpartyUuid,
        agreement_items_delivery_at: formData.deliveryDate,
        discount_code: formData.discountCode,
      };

      if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
        apiData = { ...apiData, initiator_bank_id: formData.initiator_bank_id };
      } else {
        apiData = {
          ...apiData,
          counterparty_bank_id: formData.counterparty_bank_id,
        };
      }

      const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;

      if (isLogged()) {
        setLoading(true);
        // Fetch data from the API
        fetch(apiUrl, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`, // Replace with your authorization method
          }),
          body: JSON.stringify(apiData), // Convert the object to JSON and send in the request body
        })
          .then((response) => response.json())
          .then((data) => {
            // Handle the response data as needed
            message.success("Transakcja została zaktualizowana.");
            dispatch({
              type: "transactionDataUpdated",
              payload: {
                transactionDataChanged: new Date().getSeconds(),
              },
            });
            setEditTransactionModalOpen(false);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error creating transaction:", error);
          });
      }
    }
  };

  const validateFormData = () => {
    // Walidacja danych
    if (
      !formData.title ||
      !formData.agreementText ||
      !formData.deliveryCost ||
      !formData.deliveryMethod ||
      !formData.returnMethod ||
      !formData.shipping_fee_paid_by_account_uuid ||
      !formData.escrow_fee_paid_by_account_uuid ||
      !formData.deliveryDate ||
      !formData.inspectionDuration
    ) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    return true;
  };

  const handleNewTransaction = () => {
    setEditTransactionModalOpen(true);
  };

  const handleCancelNewTransaction = () => {
    setEditTransactionModalOpen(false);
  };

  const handleChangeAgreement = (value) => {
    Modal.confirm({
      title: "Czy na pewno chcesz zaktualizować pole umowy nowym wzorem?",
      content:
        "Jeśli dokonałeś zmian w umowie bez ich zapisania, te zmiany zostaną utracone.",
      cancelText: "Anuluj",
      onOk() {
        setFormData({
          ...formData,
          agreementText: value,
        });
      },
    });
  };

  return (
    <>
      <Button
        disabled={
          (props.transaction.agreement_accepted_by_initiator &&
            props.transaction.agreement_accepted_by_counterparty) ||
          props.transaction.finished
        }
        onClick={handleNewTransaction}
        type="primary"
      >
        Edycja umowy
      </Button>

      <Modal
        title="Zmiany w transakcji"
        open={editTransactionModalOpen} // Zmieniłem "open" na "visible"
        onOk={handleUpdateTransaction}
        onCancel={handleCancelNewTransaction}
        okText="Zmień"
        cancelText="Anuluj"
        width={1000}
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Steps
              size="small"
              current={0}
              items={[
                {
                  title: "Zmiany w umowie",
                },
                {
                  title: "Zmiany przedmiotów umowy",
                },
              ]}
            />
            <Divider></Divider>

            {true ? (
              <>
                <Collapse
                  defaultActiveKey={["1"]}
                  accordion
                  items={[
                    {
                      key: "1",
                      label: "Umowa i rola w transkacji",
                      children: (
                        <>
                          {" "}
                          <Space
                            size={30}
                            direction="vertical"
                            style={{ width: "100%" }}
                          >
                            <Input
                              placeholder="Tytuł transakcji np. Sprzedaż/Kupno roweru"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  title: e.target.value,
                                })
                              }
                              value={formData.title}
                            />
                            <Select
                              style={{
                                width: 270,
                              }}
                              allowClear
                              showSearch
                              placeholder="Wybierz wzór umowy"
                              options={agreementTypes}
                              onChange={handleChangeAgreement}
                            />

                            <TextArea
                              placeholder="Tekst umowy np. Przedmiotem niniejszej umowy jest sprzedaż roweru o następujących parametrach..."
                              rows={14}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  agreementText: e.target.value,
                                })
                              }
                              value={formData.agreementText}
                            />
                            {placeholders.length > 0 ? (
                              <>
                                <Card>
                                  <Space direction="vertical">
                                    Uzupełnij dane w wybranym szablonie umowy:
                                    <ul>
                                      {placeholders.map(
                                        (placeholder, index) => (
                                          <li key={index}>{placeholder}</li>
                                        )
                                      )}
                                    </ul>
                                  </Space>
                                </Card>
                              </>
                            ) : (
                              <></>
                            )}
                          </Space>
                        </>
                      ),
                    },
                    {
                      key: "2",
                      label: "Konto bankowe do przelewu środków",
                      children: (
                        <>
                          {" "}
                          <Space>
                            <Select
                              style={{
                                width: 150,
                              }}
                              allowClear
                              placeholder="Konto bankowe"
                              options={bankAccounts}
                              onChange={(value) => {
                                if (
                                  isTransactionInitiatedByLoggedAccount(
                                    props.transaction
                                  )
                                ) {
                                  setFormData({
                                    ...formData,
                                    initiator_bank_id: value,
                                  });
                                } else {
                                  setFormData({
                                    ...formData,
                                    counterparty_bank_id: value,
                                  });
                                }
                              }}
                              value={
                                isTransactionInitiatedByLoggedAccount(
                                  props.transaction
                                )
                                  ? formData.initiator_bank_id
                                  : formData.counterparty_bank_id
                              }
                            />

                            <NewBankForm></NewBankForm>
                          </Space>
                        </>
                      ),
                    },
                    {
                      key: "3",
                      label: "Koszty dostawy i zwrotu",
                      children: (
                        <>
                          {" "}
                          <Space size={30} direction="vertical">
                            <Alert
                              type="info"
                              message="MarketB2B.pl zapewnia bezpieczne transakcje i uwzględnia możliwość zwrotu towaru. W trakcie tworzenia nowej transakcji określamy jasne warunki między Sprzedawcą a Nabywcą, dlatego konieczne jest podanie sposobu ewentualnego zwrotu towaru już na etapie tworzenia transakcji."
                            ></Alert>
                            <Input
                              style={{ width: 150 }}
                              placeholder="Koszt dostawy w zł"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  deliveryCost: e.target.value,
                                })
                              }
                              value={formData.deliveryCost}
                            />
                            <Space>
                              <Select
                                style={{
                                  width: 150,
                                }}
                                allowClear
                                placeholder="Metoda dostawy towaru/usługi"
                                options={deliveryTypes}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    deliveryMethod: value,
                                  })
                                }
                                value={formData.deliveryMethod}
                              />

                              <Select
                                style={{
                                  width: 150,
                                }}
                                allowClear
                                placeholder="Metoda zwrotu towaru/usługi"
                                options={deliveryTypes}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    returnMethod: value,
                                  })
                                }
                                value={formData.returnMethod}
                              />
                            </Space>
                          </Space>
                        </>
                      ),
                    },
                    {
                      key: "4",
                      label: "Podział kosztów obsługi transakcji",
                      children: (
                        <>
                          {" "}
                          <Space size={30} direction="vertical">
                            <Space>
                              <Select
                                style={{
                                  width: 150,
                                }}
                                placeholder="Koszty dostawy pokrywa"
                                options={transactionRoles}
                                allowClear
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    shipping_fee_paid_by_role_id: value,
                                  })
                                }
                                value={formData.shipping_fee_paid_by_role_id}
                              />

                              <Select
                                style={{
                                  width: 150,
                                }}
                                allowClear
                                placeholder="Koszty usługi edpozyt.pl pokrywa"
                                options={transactionRoles}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    escrow_fee_paid_by_role_id: value,
                                  })
                                }
                                value={formData.escrow_fee_paid_by_role_id}
                              />
                            </Space>
                            <Alert
                              type="info"
                              message="Jeśli posiadasz kod obniżający prowizję w MarketB2B.pl, wpisz go tutaj."
                            ></Alert>
                            <Input
                              style={{
                                width: 150,
                              }}
                              placeholder="Kod rabatowy"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  discountCode: e.target.value,
                                })
                              }
                              value={formData.discountCode}
                            />
                          </Space>
                        </>
                      ),
                    },
                    {
                      key: "5",
                      label: "Termin dostawy i inspekcji",
                      children: (
                        <>
                          <Space size={30} direction="vertical">
                            <Space>
                              <DatePicker
                                selected={formData.deliveryDate}
                                onChange={(date) =>
                                  setFormData({
                                    ...formData,
                                    deliveryDate: date,
                                  })
                                }
                              />

                              <Select
                                style={{
                                  width: 150,
                                }}
                                allowClear
                                placeholder="Czas trwania inspekcji"
                                options={[
                                  {
                                    value: 7,
                                    label: "7 dni",
                                  },
                                  {
                                    value: 14,
                                    label: "14 dni",
                                  },
                                  {
                                    value: 21,
                                    label: "21 dni",
                                  },
                                ]}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    inspectionDuration: value,
                                  })
                                }
                                value={formData.inspectionDuration}
                              />
                            </Space>
                          </Space>
                        </>
                      ),
                    },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditTransactionForm;
