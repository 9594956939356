import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Divider,
  Modal,
  Input,
  message,
  Spin,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../../../../helpers/account";
import { api } from "../../../../../config/api";
import { useDispatch } from "react-redux";

const EditAccountForm = () => {
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null); // For avatar preview
  const [avatarFile, setAvatarFile] = useState(null); // For storing the selected file
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(false);

  // Convert file to base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  // Upload avatar to the server
  const handleUploadAvatar = async () => {
    try {
      if (!avatarFile) {
        message.error("Nie wybrano pliku.");
        return;
      }

      setUploading(true);
      const base64Image = await convertFileToBase64(avatarFile);

      const response = await fetch(`${api.host}/upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image: base64Image }),
      });

      if (!response.ok) {
        throw new Error("Błąd przesyłania avatara.");
      }

      const data = await response.json();

      const apiData = {
        photo: data.publicUrl,
      };

      const apiUrl = `${api.host}/accounts/${formData.uuid}`;

      setLoading(true);
      fetch(apiUrl, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
        body: JSON.stringify(apiData),
      })
        .then((response) => response.json())
        .then(() => {
          dispatch({
            type: "accountDataChanged",
            payload: {
              accountDataChanged: new Date().getSeconds(),
            },
          });

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating account:", error);
          message.error("Błąd podczas aktualizacji danych.");
          setLoading(false);
        });

      message.success("Avatar został ustawiony.");
      setAvatarPreview(null); // Clear preview
      setAvatarFile(null); // Clear file
    } catch (error) {
      console.error("Error uploading avatar:", error);
      message.error("Błąd podczas przesyłania avatara.");
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (formData === false && sessionStorage.getItem("authToken")) {
      const apiUrl = `${api.host}/accounts/${sessionStorage.getItem("uuid")}`;
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching account data:", error);
          message.error("Dla bezpieczeństwa zostałeś wylogowany.");
          destroyAuthToken();
          window.location.href = "/transactions";
          setLoading(false);
        });
    }
  }, [formData]);

  const handleUpdateAccount = () => {
    const apiData = {
      fullname: formData.fullname,
      mobile: formData.mobile,
      company_name: formData.company_name,
      address: formData.address,
      post_code: formData.post_code,
      city: formData.city,
      nip: formData.nip,
    };

    const apiUrl = `${api.host}/accounts/${formData.uuid}`;

    if (isLogged()) {
      setLoading(true);
      fetch(apiUrl, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
        body: JSON.stringify(apiData),
      })
        .then((response) => response.json())
        .then(() => {
          dispatch({
            type: "accountDataChanged",
            payload: {
              accountDataChanged: new Date().getSeconds(),
            },
          });
          message.success("Dane konta zostały zaktualizowane.");
          setEditAccountModalOpen(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating account:", error);
          message.error("Błąd podczas aktualizacji danych.");
          setLoading(false);
        });
    }
  };

  const handleEditAccount = () => {
    setEditAccountModalOpen(true);
  };

  const handleCancelEditAccount = () => {
    setEditAccountModalOpen(false);
  };

  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) {
      message.error("Nie udało się załadować pliku. Spróbuj ponownie.");
      return;
    }
    setAvatarFile(file);
    const reader = new FileReader();
    reader.onload = () => setAvatarPreview(reader.result); // Display image preview
    reader.onerror = () => {
      message.error("Błąd podczas generowania podglądu avatara.");
    };
    reader.readAsDataURL(file); // Generate preview
  };

  return (
    <>
      <Button onClick={handleEditAccount} type="primary">
        Zmiana danych
      </Button>

      <Modal
        title="Zmiana danych konta"
        open={editAccountModalOpen}
        onOk={handleUpdateAccount}
        onCancel={handleCancelEditAccount}
        okText="Zaktualizuj"
        cancelText="Anuluj"
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Reprezentant firmy"
              onChange={(e) =>
                setFormData({ ...formData, fullname: e.target.value })
              }
              value={formData.fullname}
            />
            <Input
              placeholder="Nazwa firmy"
              onChange={(e) =>
                setFormData({ ...formData, company_name: e.target.value })
              }
              value={formData.company_name}
            />
            <Input
              placeholder="Telefon"
              onChange={(e) =>
                setFormData({ ...formData, mobile: e.target.value })
              }
              value={formData.mobile}
            />
            <Input
              placeholder="Adres"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              value={formData.address}
            />
            <Space>
              <Input
                placeholder="Kod pocztowy"
                onChange={(e) =>
                  setFormData({ ...formData, post_code: e.target.value })
                }
                value={formData.post_code}
              />
              <Input
                placeholder="Miasto"
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
                value={formData.city}
              />
            </Space>
            <Input
              placeholder="NIP"
              onChange={(e) =>
                setFormData({ ...formData, nip: e.target.value })
              }
              value={formData.nip}
            />
            <Divider />
            <Upload
              accept="image/*"
              beforeUpload={() => false}
              onChange={handleFileChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Wybierz avatar</Button>
            </Upload>
            {avatarPreview && (
              <img
                src={avatarPreview}
                alt="Avatar preview"
                style={{ width: 100, height: 100, borderRadius: "50%" }}
              />
            )}
            <Button
              type="primary"
              onClick={handleUploadAvatar}
              loading={uploading}
              disabled={!avatarFile}
            >
              Prześlij avatar
            </Button>
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditAccountForm;
