import React, { useState } from "react";
import { Button, Modal, message, Spin } from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";

const DeleteInquiryResponseButton = ({ inquiryUuid, responseUuid, onResponseDeleted }) => {
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteResponse = () => {
    setLoading(true);

    const apiUrl = `${api.host}/inquiries/${inquiryUuid}/responses/${responseUuid}`;

    if (isLogged()) {
      fetch(apiUrl, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete inquiry response");
          }
          return response.json();
        })
        .then(() => {
          setLoading(false);
          dispatch({
            type: "inquiryResponseDeleted",
            payload: {
              inquiryResponseDeleted: new Date().getSeconds(),
            },
          });
          message.success("Odpowiedź została pomyślnie usunięta.");
          setDeleteModalOpen(false);
          if (onResponseDeleted) {
            onResponseDeleted();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error while deleting inquiry response:", error);
          message.error("Wystąpił błąd podczas usuwania odpowiedzi.");
        });
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button danger onClick={handleOpenDeleteModal}>
          Usuń odpowiedź
        </Button>

        <Modal
          title="Usuń odpowiedź"
          open={deleteModalOpen}
          onOk={handleDeleteResponse}
          onCancel={handleCancelDeleteModal}
          okText="Usuń"
          cancelText="Anuluj"
        >
          <p>Czy na pewno chcesz usunąć tę odpowiedź?</p>
        </Modal>
      </Spin>
    </>
  );
};

export default DeleteInquiryResponseButton;
