import {
    Typography,
    Space,
    Card,
    Spin,
    Table,
    Tag,
  } from "antd";
  import React, { useState, useEffect } from "react";
  import { api } from "../../config/api";
  import { formatPolishCurrency } from "../../helpers/currency";
  import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
  
  const { Title } = Typography;
  
  // Define table columns
  const columns = [
    {
      title: "Nazwa aktywa",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name, record) => (
        <>
          <Space direction="vertical">
            <Title level={5} style={{ margin: 0 }}>
              <u style={{ cursor: "pointer" }}>{name}</u>
            </Title>
            <Space direction="vertical">
              <Tag>{record.asset_type?.name}</Tag>
              <Space>
                <AvatarDisplay
                  account={record.account}
                  src={record.account?.photo}
                  size={32}
                />{" "}
                {record.account?.fullname}
              </Space>
            </Space>
          </Space>
        </>
      ),
    },
    {
      title: "Segment docelowy",
      dataIndex: "target_segment",
      key: "target_segment",
      sorter: true,
      render: (target_segment) => (
        <Tag color="blue">{target_segment}</Tag>
      ),
      className: "hide-on-mobile",
    },  
    {
      title: "Zysk z ostatnich 12 miesięcy",
      dataIndex: "ttm_profit",
      key: "ttm_profit",
      sorter: true,
      render: (ttm_profit) => (
        <Tag color="gold-inverse">{formatPolishCurrency(ttm_profit)}</Tag>
      ),
      className: "hide-on-mobile",
    },    
    {
      title: "Cena",
      dataIndex: "price",
      key: "price",
      sorter: true,
      render: (price) => (
        <Tag color="gold-inverse">{formatPolishCurrency(price)}</Tag>
      ),
    }
  ];
  
  export const Assets = ({ limit, paginationLimit }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // API endpoint URL for fetching assets
      const apiUrl = `${api.host}/assets/public`;
  
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const limitedData = limit ? data.slice(0, limit) : data; // Apply limit if specified
          setData(limitedData); // Update the state with the data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching assets:", error);
          setLoading(false); // Set loading to false in case of error
        });
    }, [limit]);
  
    return (
      <Card size="small">
        <Spin spinning={loading}>
          <Table
            onRow={(record) => ({
              onClick: () => {
                window.location.href = `/marketplace/asset/${record.uuid}`;
              },
            })}
            className="shadow"
            columns={columns}
            dataSource={data}
            size="small"
            pagination={{
              pageSize: paginationLimit || 10, // Default page size
              showSizeChanger: true, // Allow user to adjust page size
              pageSizeOptions: ["5", "10", "20", "50"], // Page size options
              locale: { items_per_page: "na stronę" }, // Polish translation
            }}
            locale={{
              emptyText: "Brak dostępnych aktywów, spróbuj później.",
            }}
          />
        </Spin>
      </Card>
    );
  };
  