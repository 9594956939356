import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Space, Breadcrumb, Typography } from "antd";
import LoginPage from "../Login/LoginPage";
import { isLogged } from "../../helpers/account";
import { Catalog } from "../Offer/CatalogPage";
import {
    HomeOutlined,
    SafetyCertificateTwoTone
} from "@ant-design/icons";
const { Title, Text } = Typography;

const OffersPage = () => {
    if (isLogged()) {
        return (
            <>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">
                            <Title level={2}>
                                Zamów usługę od firmy w marketb2b.pl
                            </Title>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Zamów usługę od firmy w marketb2b.pl",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}>

                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Catalog />
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
};

export default OffersPage;
