import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  DatePicker,
  InputNumber,
  Checkbox,
} from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const { TextArea } = Input;

const EditInquiryForm = ({ inquiryUuid, onInquiryUpdated }) => {
  const [editInquiryModalOpen, setEditInquiryModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    budget: "",
    message: "",
    valid_to: null,
    delivery_days: "",
    is_active: false, // Added is_active field
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (editInquiryModalOpen && inquiryUuid) {
      setLoading(true);
      const apiUrl = `${api.host}/inquiries/${inquiryUuid}`;
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch inquiry data");
          }
          return response.json();
        })
        .then((data) => {
          setFormData({
            name: data.name,
            budget: data.budget,
            message: data.message,
            valid_to: data.valid_to ? dayjs(data.valid_to) : null,
            delivery_days: data.delivery_days,
            is_active: data.is_active, // Set is_active from API data
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching inquiry data:", error);
          message.error("Nie udało się pobrać danych zapytania.");
          setLoading(false);
        });
    }
  }, [editInquiryModalOpen, inquiryUuid]);

  const handleEditInquiry = () => {
    if (validateFormData()) {
      setLoading(true);

      const apiData = {
        name: formData.name,
        budget: formData.budget,
        message: formData.message,
        valid_to: formData.valid_to
          ? formData.valid_to.format("YYYY-MM-DD HH:mm:ss")
          : "",
        delivery_days: formData.delivery_days,
        is_active: formData.is_active, // Include is_active in the API payload
      };

      const apiUrl = `${api.host}/inquiries/${inquiryUuid}`;

      if (isLogged()) {
        fetch(apiUrl, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to update inquiry");
            }
            return response.json();
          })
          .then(() => {
            setLoading(false);
            dispatch({
              type: "inquiryDataUpdated",
              payload: {
                inquiryDataChanged: new Date().getSeconds(),
              },
            });
            message.success("Zapytanie zostało pomyślnie zaktualizowane.");
            setEditInquiryModalOpen(false);
            if (onInquiryUpdated) {
              onInquiryUpdated();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while updating inquiry:", error);
            message.error("Wystąpił błąd podczas aktualizacji zapytania.");
          });
      }
    }
  };

  const validateFormData = () => {
    if (
      !formData.name ||
      !formData.budget ||
      !formData.message ||
      !formData.valid_to ||
      !formData.delivery_days
    ) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    if (isNaN(parseFloat(formData.budget))) {
      message.error("Budżet musi być liczbą.");
      return false;
    }
    if (isNaN(parseInt(formData.delivery_days))) {
      message.error("Czas dostawy musi być liczbą.");
      return false;
    }
    return true;
  };

  const handleOpenEditModal = () => {
    setEditInquiryModalOpen(true);
  };

  const handleCancelEdit = () => {
    setEditInquiryModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button onClick={handleOpenEditModal}>
          Edytuj
        </Button>

        <Modal
          title="Edytuj zapytanie"
          open={editInquiryModalOpen}
          onOk={handleEditInquiry}
          onCancel={handleCancelEdit}
          okText="Zapisz"
          cancelText="Anuluj"
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Nazwa zapytania"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
            />

            <InputNumber
              placeholder="Budżet"
              min={1}
              onChange={(value) => setFormData({ ...formData, budget: value })}
              value={formData.budget}
            />
            <TextArea
              placeholder="Treść zapytania ofertowego"
              rows={4}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              value={formData.message}
            />
            <DatePicker
              format="YYYY-MM-DD"
              placeholder="Ważne do (YYYY-MM-DD)"
              onChange={(date) => setFormData({ ...formData, valid_to: date })}
              value={formData.valid_to}
            />
            <InputNumber
              placeholder="Czas dostawy (dni)"
              min={1}
              onChange={(value) =>
                setFormData({ ...formData, delivery_days: value })
              }
              value={formData.delivery_days}
            />
            <Checkbox
              checked={formData.is_active}
              onChange={(e) =>
                setFormData({ ...formData, is_active: e.target.checked })
              }
            >
              Widoczne publicznie
            </Checkbox>
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default EditInquiryForm;
