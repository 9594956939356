import React from "react";
import { Link } from "react-router-dom";
import LoginPage from "../Login/LoginPage";
import {
  Card,
  Space,
  Row,
  Col,
  Menu,
  Breadcrumb,
  Divider,
  Typography,
} from "antd";
import { isLogged } from "../../helpers/account";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

function AccountPage() {
  if (isLogged()) {
    const menuItems = [
      {
        key: "address-data",
        label: "Numer telefonu i adres e-mail",
        link: "/account/data",
      },
      {
        key: "bank-accounts",
        label: "Konta bankowe",
        link: "/account/bank-accounts",
      },
    ];

    return (
      <>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>Moje dane</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/transactions",
                    title: <HomeOutlined />,
                  },
                  {
                    title: "Moje Dane",
                  },
                ]}
              />
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}></Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space size={30} direction="vertical" style={{ width: "100%" }}>
              <Card size="small">
                <Menu
                  style={{
                    width: 256,
                    borderInlineEnd: 0,
                  }}
                >
                  {menuItems.map((item) => (
                    <Menu.Item key={item.key}>
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu>
              </Card>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default AccountPage;
