import { Button, Modal, Card, Space, DatePicker, Input, Radio } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const CreateTransactionPage = () => {
  const [visible, setVisible] = useState(null);
  const [value3, setValue3] = useState("Apple");

  const onChange3 = ({ target: { value } }) => {
    console.log("radio3 checked", value);
    setValue3(value);
  };

  const goodsType = [
    {
      label: "Dostawa wirtualna",
      value: "Apple",
    },
    {
      label: "Dostawa tradycyjna",
      value: "Pear",
    },
  ];

  const questions = [
    {
      key: "1",
      label: "Czy MarketB2B.pl zabezpiecza transakcje o małej wartości?",
      content: (
        <p>
          MarketB2B.pl, zapewniając ochronę i bezpieczeństwo, został zoptymalizowany
          przede wszystkim dla transakcji powyżej 50 zł, co oznacza, że jego
          mechanizmy ochronne są najskuteczniejsze w przypadku większych kwot.
        </p>
      ),
    },
    {
      key: "2",
      label:
        "Czy uzyskam fakturę za usługę zabezpieczenia transakcji przez MarketB2B.pl?",
      content: (
        <p>
          Oczywiście. Jako firma działająca zgodnie z polskim prawem, jesteśmy
          zobowiązani do wystawienia faktury za świadczone usługi zabezpieczenia
          transakcji. Dzięki temu otrzymasz dokument potwierdzający dokonaną
          transakcję, który będzie spełniał wymogi i przepisy polskiego prawa.
        </p>
      ),
    },
    {
      key: "3",
      label:
        "Ile ostatecznie zapłacę jako kupujący w przypadku zabezpieczenia transakcji kupna roweru za 3000 zł, a ile otrzyma sprzedawca na swoje konto?",
      content: (
        <p>
          Wartość przedmiotów w transakcji: 3 000,00 zł
          <br />
          Kwota do wpłaty przez kupującego: 3 000,00 zł
          <br />
          Kwota, którą otrzyma sprzedający: 2 895,45 zł
          <br />
          <br />
          Prowizja Postpay: 104,55 zł
          <br />
          <br />
          W tym przypadku prowizja Postpay wynosi 104,55 zł. To jest opłata za
          usługę Postpay, która jest pobierana od sprzedawcy (wg zawartej
          umowy).
          <br />
          <br />
          Koszty dostawy: 130,00 zł
          <br />
          <br />
          Dodatkowe koszty dostawy wynoszą 130,00 zł. Te koszty ponosi Katarzyna
          Kowalska, która jest sprzedającą stroną w tej transakcji.
          <br />
          <br />
          Podsumowując, sprzedająca Katarzyna Kowalska ponosi koszty dostawy w
          wysokości 130,00 zł oraz prowizję Postpay w wysokości 104,55 zł.
          Całkowita kwota do zapłaty przez kupującego wynosi 3 000,00 zł.
          Natomiast sprzedający otrzyma kwotę 2 895,45 zł za sprzedany rower.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Wpłaciłem depozyt do Postpay ale sprzedawca przysłał mi towar niezgodny z umową, co wtedy?",
      content: (
        <p>
          Jeśli wpłaciłeś depozyt do Postpay, a sprzedawca dostarczył ci towar
          niezgodny z umową, to masz możliwość rozwiązania takiej sytuacji.
          Pierwszym krokiem jest kontakt z sprzedawcą w celu wyjaśnienia
          sytuacji. Jeśli nie jesteś w stanie osiągnąć satysfakcjonującego
          rozwiązania z sprzedawcą, skontaktuj się z Postpay i zgłoś spór,
          opisując sytuację i przekazując dowody na niezgodność towaru z umową.
          Postpay będzie starał się pomóc w rozwiązaniu sporu, analizując
          dostępne dowody i dążąc do sprawiedliwego rozwiązania. Postpay działa
          jako pośrednik i stara się zapewnić uczciwe rozstrzygnięcie sporu, co
          chroni Twoje interesy w przypadku problemów z transakcją.
        </p>
      ),
    },
    {
      key: "5",
      label: "Jakie sposoby dostawy są wspierane przez Postpay",
      content: (
        <p>
          Postpay wspiera sposoby dostawy, które umożliwiają śledzenie przesyłki
          za pomocą numeru identyfikacyjnego. Oto opis obsługiwanych sposobów
          dostawy: Orlen Paczka, Paczkomaty Inpost, DPD, FedEx, Kurier InPost,
          Pocztex, DHL, Poczta Polska, GLS, Paczka w Ruchu, UPS, Odbiór
          osobisty, E-mail.
        </p>
      ),
    },
    {
      key: "6",
      label: "Jakie rodzaje umów wspiera Postpay",
      content: (
        <p>
          Postpay jest elastyczną platformą, która umożliwia stronom pełną
          dowolność w tworzeniu i definiowaniu swoich umów. Jednak dla wygody
          użytkowników udostępniamy również 17 gotowych wzorów umów, które można
          wykorzystać natychmiastowo. Dzięki temu masz możliwość dostosowania
          umowy do swoich indywidualnych potrzeb lub skorzystania z jednego z
          naszych gotowych szablonów, co zapewnia szybkość i wygodę w zawieraniu
          transakcji. Dostępne wzory umów:{" "}
          {[
            "Umowa o wykonanie rękodzieła",
            "Umowa o  sprzedaż sklepu internetowego",
            "Umowa o usługi aktualizacyjne/poprawkowe serwera",
            "Umowa o dostarczenie tekstów seo",
            "Umowa o wykonanie audytu technicznego strony",
            "Umowa o wykonanie audytu seo",
            "Umowa o wykonanie logo, plakatu i grafiki",
            "Umowa o wykonanie modyfikacji w sklepie/stronie",
            "Umowa o wykonanie robót budowlanych",
            "Umowa o sprzedaż profilu na Instagram",
            "Umowa o wykonanie sklepu internetowego",
            "Umowa o sprzedaż roweru",
            "Umowa o sprzedaż grupy Facebook",
            "Umowa o sprzedaż antyków z poświa. autentyczności",
            "Umowa o sprzedaż samochodu",
            "Umowa o sprzedaż koparki",
            "Umowa prosta",
          ].map((item, index) => (
            <span
              key={index}
              style={{
                margin: "5px",
                display: "inline-block",
                background: "#eee",
                padding: "2px 8px",
                borderRadius: "4px",
              }}
            >
              {item}
            </span>
          ))}
        </p>
      ),
    },
  ];

  return (
    <>
      {" "}
      <Helmet>
        <title>Przelew, Blik - Ochrona</title>
      </Helmet>
      <div class="hero-background-create-transaction">
        <div class="hero-background-3">
          <img src="/img/svg/1.svg" alt="" />
        </div>
        <div class="shield">
          <img src="/img/svg/16.svg" alt="" />
        </div>
        <div class="hero-background-5-create-transaction">
          Z Postpay bez ryzyka przekażesz zapłatę za towary i usługi nabywane od
          “ludzi z Internetu”
        </div>
        <div class="hero-background-7-create-transaction">
          <Card
            className="create-transaction-form"
          >
            <Space size={10} direction="vertical">
              <h2 className="font-1">Jaka jest Twoja rola w transakcji?</h2>
              <Radio.Group
                options={[
                  {
                    value: 1,
                    label: "Sprzedawca",
                  },
                  {
                    value: 0,
                    label: "Nabywca",
                  },
                ]}
                onChange={onChange3}
                value={value3}
                optionType="button"
              />
              <h3 className="font-1" style={{ marginBottom: 0 }}>
                Co jest przedmiotem transakcji?
              </h3>
              <span class="create-transaction-item-help">
                Kolejne przedmioty oraz treść umowy pomiędzy stronami dodasz po
                utworzeniu transakcji.
              </span>
              <Input
                style={{
                  width: 310,
                }}
                placeholder="Np. strona www, logo, rower, rzeźba..."
              />

              <Input
                addonBefore="za"
                addonAfter="zł"
                style={{
                  width: 150,
                }}
                placeholder="3000"
              />

              <h3 className="font-1">
                W jaki sposób i kiedy dojdzie do odbioru przedmiotu transakcji?
              </h3>
              <Radio.Group
                options={goodsType}
                onChange={onChange3}
                value={value3}
                optionType="button"
              />
              <div style={{ zIndex: 1200 }}>
                <DatePicker />
              </div>
              <h3 className="font-1">
                Na jakie adresy e-mail mamy wysłać potwierdzenie rozpoczęcia
                transkacji?
              </h3>
              <Input
                style={{
                  width: 310,
                }}
                placeholder="Adres e-mail sprzedawcy"
              />
              <Input
                style={{
                  width: 310,
                }}
                placeholder="Adres e-mail nabywcy"
              />

              <Link to="/account/register">
                <Button type="primary" size="large">
                  Utwórz transakcję
                </Button>
              </Link>
              <span class="create-transaction-item-help">
                Utworzymy transakcję na warunkach, które ustalisz ze stroną
                transakcji. Aby przejść do następnego etapu transakcji czyli
                przekazania płatności, strony transakcji muszą zaakceptować
                ustalone warunki. Wtedy poprosimy o wpłatę środków, nie
                wcześniej.
              </span>
            </Space>
          </Card>
        </div>

        <div class="hero-background-8-create-transaction">
          Podaj szczegóły zakupu oraz warunki wypłaty pieniędzy do strony, od
          której nabywasz towar/usługę.
        </div>

        <div class="hero-background-4-create-transaction">
          <img src="/img/svg/15.svg" alt="" />
        </div>
      </div>
      <div class="text-head-1-create-transaction">
        Postpay przechowa wpłatę do czasu otrzymania towaru lub usługi
      </div>
      <div class="text-lead-1-create-transaction">
        Skomplikowane mechanizmy obsługi różnych scenariuszy transakcji oraz
        dziesiątek punktów decyzyjnych zamknięte w jednej, łatwej do korzystania
        usłudze. Dzięki Postpay możesz spokojnie i pewnie dokonywać płatności
        oraz zapewnić bezpieczeństwo swoim transakcjom online.
      </div>
      <div class="partners-create-transaction">
        <img src="/img/svg/10.svg" alt="" />
      </div>
      <div class="partners-logo-1-create-transaction">
        <img src="/img/svg/11.svg" alt="" />
      </div>
      <div class="partners-logo-2-create-transaction">
        <img src="/img/svg/12.svg" alt="" />
      </div>
      <div class="partners-logo-3-create-transaction">
        <img src="/img/svg/14.svg" alt="" />
      </div>
      <div class="trusted-tech-create-transaction">
        Postpay używa zaufanych technologii
      </div>
      <div class="questions-icon"></div>
      <div class="text-head-2-create-transaction">
        Najczęściej zadawane pytania
      </div>
      <div class="questions-create-transaction">
        {questions.map((question) => (
          <div key={question.key} style={{ marginBottom: "16px" }}>
            <a
              href=""
              style={{ width: "300px", overflowX: "hidden" }}
              type="link"
              onClick={(e) => {
                e.preventDefault();
                setVisible(question.key);
              }}
            >
              {question.label}
            </a>
            <Modal
              title={question.label}
              visible={visible === question.key}
              onCancel={() => setVisible(null)}
              footer={[
                <Button key="close" onClick={() => setVisible(null)}>
                  Zamknij
                </Button>,
              ]}
            >
              {question.content}
            </Modal>
          </div>
        ))}
      </div>
    </>
  );
};

export default CreateTransactionPage;
