import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import {
  Table,
  Card,
  Space,
  Row,
  Col,
  message,
  Divider,
  Breadcrumb,
  Typography,
  Tag,
} from "antd";
import { formatPolishDate } from "../../helpers/date";
import { formatPolishCurrency } from "../../helpers/currency";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../helpers/account";
import { api } from "../../config/api";
import { NotificationOutlined, HomeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import NewInquiryForm from "../Inquiry/Forms/NewInquiryForm";
const { Title } = Typography;

const columns = [
  {
    title: "Ważne do",
    dataIndex: "valid_to",
    key: "valid_to",
    render: (valid_to) => formatPolishDate(valid_to,'day'),
    className: "hide-on-mobile",
  },
  {
    title: "Nazwa zapytania",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: true,
    render: (name) => (
      <>
        <u style={{ cursor: "pointer" }}>{name}</u>
      </>
    ),
  },
  {
    title: "Budżet",
    dataIndex: "budget",
    key: "budget",
    render: (budget) => {
      return <>
        <Tag color="gold-inverse">{formatPolishCurrency(budget)}</Tag>
      </>;
    },
  },
];

function InquiresPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const inquiryDataChanged = useSelector((state) => state.inquiryDataChanged);

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/inquiries`; // Update with your API endpoint

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data); // Update the state with the fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/transactions";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [inquiryDataChanged]);

  if (isLogged()) {
    return (
      <>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>Moje zapytania</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    title: "Moje zapytania",
                  },
                ]}
              />
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}></Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>

        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space size={30} direction="vertical" style={{ width: "100%" }}>
              <NewInquiryForm />
              <Card size="small">
                <Table
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        window.location.href = `/inquiry/${record.uuid}`;
                      }, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  className="shadow"
                  columns={columns}
                  dataSource={data}
                  loading={loading}
                  locale={{
                    emptyText:
                      "Jeszcze nie dodałeś żadnego zapytania ofertowego",
                  }}
                />
              </Card>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default InquiresPage;
