import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import { Card, Space, Row, Col, Breadcrumb, Typography, Tag, Divider, Descriptions, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged, getAuthToken } from "../../helpers/account";
import { Helmet } from "react-helmet-async";
import { CalendarTwoTone, HomeOutlined, EuroCircleTwoTone, LineChartOutlined, TeamOutlined, EyeOutlined } from "@ant-design/icons";
import EditAssetForm from "./Forms/EditAssetForm";
import DeleteAssetButton from "./Forms/DeleteAssetButton";

const { Title } = Typography;

const AssetPage = () => {
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState({});
  const { assetUuid } = useParams();

  const fetchAssetData = async () => {
    try {
      const assetResponse = await fetch(
        `${api.host}/assets/${assetUuid}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        }
      );
      const assetData = await assetResponse.json();
      setAsset(assetData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching asset data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetData();
  }, [assetUuid]);

  return (
    <>
      <Helmet>
        <title>{asset.name || "Szczegóły aktywa"}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>{asset.name}</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/assets",
                    title: "Moje aktywa",
                  },
                  {
                    title: asset.name,
                  },
                ]}
              />
              {asset.is_active ? (
                <Tag color="green">Aktywne</Tag>
              ) : (
                <Tag color="magenta">Nieaktywne</Tag>
              )}
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider />
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Row gutter={12}>
              <Col md={24}>
                <Space direction="vertical" style={{width:"100%"}}>
                  <Space>
                    <EditAssetForm
                      assetUuid={asset.uuid}
                      onAssetUpdated={() => {
                        console.log("Asset updated successfully!");
                        fetchAssetData(); // Refresh data
                      }}
                    />
                    <DeleteAssetButton
                      assetUuid={asset.uuid}
                      onDeleteSuccess={() => {
                        console.log("Asset deleted successfully!");
                        window.location.href = "/assets";
                      }}
                    />
                  </Space>
                  <br />
                  <Descriptions layout="vertical" bordered>
                    <Descriptions.Item label="Cena">
                      <EuroCircleTwoTone /> {formatPolishCurrency(asset.price)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Liczba klientów">
                      <TeamOutlined /> {asset.customers}
                    </Descriptions.Item>
                    <Descriptions.Item label="Przychód z ostatnich 12 miesięcy">
                      <LineChartOutlined /> {formatPolishCurrency(asset.ttm_revenue)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Zysk z ostatnich 12 miesięcy">
                      <EuroCircleTwoTone /> {formatPolishCurrency(asset.ttm_profit)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Średnia liczba odwiedzających miesięcznie">
                      <EyeOutlined /> {asset.avg_monthly_visitors}
                    </Descriptions.Item>
                    <Descriptions.Item label="Segment docelowy">
                      {asset.target_segment || "Nie podano"}
                    </Descriptions.Item>
                  </Descriptions>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: asset.description
                        ? asset.description.replace(/\n/g, "<br>")
                        : "",
                    }}
                  ></p>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
        {asset.photo && (
          <Row>
            <Col md={4}></Col>
            <Col md={16} style={{ textAlign: "center" }}>
              <Card cover={<img alt={asset.name} src={asset.photo} style={{ maxHeight: 400, objectFit: "cover" }} />} />
            </Col>
            <Col md={4}></Col>
          </Row>
        )}
      </Space>
    </>
  );
};

export default AssetPage;