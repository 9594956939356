import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Space, Row, Col, Breadcrumb,
    Typography,
    Divider,
    Card,
    Button,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
    HomeOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import { Inquiries } from "../Offer/InquiriesPage";
const { Title } = Typography;

const MarketplaceInquiriesPage = () => {

    return (

        <>
            <Helmet>
                <title>Katalog zapytań ofertowych</title>
            </Helmet>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">
                            <Title level={2}>
                                Katalog zapytań ofertowych
                            </Title>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Katalog zapytań ofertowych",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}>

                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>
                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space direction="vertical">
                            <Card size="small" style={{ backgroundColor: "#1677ff" }}>
                                <Space direction="horizontal">
                                    Oferujesz usługi na najwyższym poziomie?{" "}
                                    <Link to="/transactions">
                                        <Button type="primary">Zacznij zdobywać klientów</Button>
                                    </Link>
                                </Space>
                            </Card>
                            <Inquiries limit={100} paginationLimit={100} />
                        </Space>

                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Space>
        </>
    );
};

export default MarketplaceInquiriesPage;
