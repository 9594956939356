import {
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Card,
  Spin,
  Tag,
} from "antd";
import React, { useState, useEffect } from "react";
import { RoughNotation } from "react-rough-notation";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Catalog } from "./CatalogPage";
import {
  SoundTwoTone,
  BuildTwoTone,
  DollarTwoTone,
  ContactsTwoTone,
  LikeTwoTone,
  BookTwoTone,
  ShopTwoTone,
} from "@ant-design/icons";

import HelpBox from "./HelpBox";
import { Inquiries } from "./InquiriesPage";
import PromotedProducts from "./PromotedProducts";
import MarketplacePromotedProductsList from "../Marketplace/MarketplacePromotedProductsList";
import RecentAccountsList from "../Marketplace/RecentAccountsList";
import { Assets } from "./AssetsPage";
const { Title } = Typography;

const WelcomePage = () => {
  const [visible, setVisible] = useState(null);

  const questions = [
    {
      key: "1",
      label: "Czy MarketB2B.PL zabezpiecza transakcje o małej wartości?",
      content: (
        <p>
          MarketB2B.PL, zapewniając ochronę i bezpieczeństwo, został
          zoptymalizowany przede wszystkim dla transakcji powyżej 500 zł, co
          oznacza, że jego mechanizmy ochronne są najskuteczniejsze w przypadku
          większych kwot.
        </p>
      ),
    },
    {
      key: "2",
      label:
        "Czy uzyskam fakturę za usługę zabezpieczenia transakcji przez MarketB2B.PL?",
      content: (
        <p>
          Oczywiście. Jako firma działająca zgodnie z polskim prawem, jesteśmy
          zobowiązani do wystawienia faktury za świadczone usługi zabezpieczenia
          transakcji. Dzięki temu otrzymasz dokument potwierdzający dokonaną
          transakcję, który będzie spełniał wymogi i przepisy polskiego prawa.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Wpłaciłem depozyt do MarketB2B.PL ale sprzedawca przysłał mi towar niezgodny z umową, co wtedy?",
      content: (
        <p>
          Jeśli wpłaciłeś depozyt do MarketB2B.PL, a sprzedawca dostarczył ci
          towar niezgodny z umową, to masz możliwość rozwiązania takiej
          sytuacji. Pierwszym krokiem jest kontakt z sprzedawcą w celu
          wyjaśnienia sytuacji. Jeśli nie jesteś w stanie osiągnąć
          satysfakcjonującego rozwiązania z sprzedawcą, skontaktuj się z
          MarketB2B.PL i zgłoś spór, opisując sytuację i przekazując dowody na
          niezgodność towaru z umową. MarketB2B.PL będzie starał się pomóc w
          rozwiązaniu sporu, analizując dostępne dowody i dążąc do
          sprawiedliwego rozwiązania. MarketB2B.PL działa jako pośrednik i stara
          się zapewnić uczciwe rozstrzygnięcie sporu, co chroni Twoje interesy w
          przypadku problemów z transakcją.
        </p>
      ),
    },
    {
      key: "5",
      label: "Jakie sposoby dostawy są wspierane przez MarketB2B.PL",
      content: (
        <p>
          MarketB2B.PL wspiera sposoby dostawy, które umożliwiają śledzenie
          przesyłki za pomocą numeru identyfikacyjnego. Oto opis obsługiwanych
          sposobów dostawy: Orlen Paczka, Paczkomaty Inpost, DPD, FedEx, Kurier
          InPost, Pocztex, DHL, Poczta Polska, GLS, Paczka w Ruchu, UPS, Odbiór
          osobisty, E-mail.
        </p>
      ),
    },
    {
      key: "6",
      label: "Jakie rodzaje umów wspiera MarketB2B.PL",
      content: (
        <p>
          MarketB2B.PL jest elastyczną platformą, która umożliwia stronom pełną
          dowolność w tworzeniu i definiowaniu swoich umów. Jednak dla wygody
          użytkowników udostępniamy również 17 gotowych wzorów umów, które można
          wykorzystać natychmiastowo. Dzięki temu masz możliwość dostosowania
          umowy do swoich indywidualnych potrzeb lub skorzystania z jednego z
          naszych gotowych szablonów, co zapewnia szybkość i wygodę w zawieraniu
          transakcji. Dostępne wzory umów:{" "}
          {[
            "Umowa o wykonanie rękodzieła",
            "Umowa o  sprzedaż sklepu internetowego",
            "Umowa o usługi aktualizacyjne/poprawkowe serwera",
            "Umowa o dostarczenie tekstów seo",
            "Umowa o wykonanie audytu technicznego strony",
            "Umowa o wykonanie audytu seo",
            "Umowa o wykonanie logo, plakatu i grafiki",
            "Umowa o wykonanie modyfikacji w sklepie/stronie",
            "Umowa o wykonanie robót budowlanych",
            "Umowa o sprzedaż profilu na Instagram",
            "Umowa o wykonanie sklepu internetowego",
            "Umowa o sprzedaż roweru",
            "Umowa o sprzedaż grupy Facebook",
            "Umowa o sprzedaż antyków z poświa. autentyczności",
            "Umowa o sprzedaż samochodu",
            "Umowa o sprzedaż koparki",
            "Umowa prosta",
          ].map((item, index) => (
            <span
              key={index}
              style={{
                margin: "5px",
                display: "inline-block",
                background: "#eee",
                padding: "2px 8px",
                borderRadius: "4px",
              }}
            >
              {item}
            </span>
          ))}
        </p>
      ),
    },
  ];

  return (
    <>
      {" "}
      <Helmet>
        <title>
          Kup usługę, dodaj zapytanie ofertowe lub znajdź swoje następne
          zlecenie bez ryzyka oszustwa
        </title>
      </Helmet>
      <Space direction="vertical">
        <Row
          justify="space-between"
          align="middle"
          style={{ minHeight: "400px" }}
          className="header"
        >
          <Col md={4}></Col>

          <Col md={8} style={{ textAlign: "center", padding: "0 15px" }}>
            <br />
            <img src="/img/svg/9.svg" alt="" style={{ width: "100%" }} />
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <Title level={1} style={{ margin: 0 }}>
                Łączymy strony umowy
              </Title>

              <Title level={5} style={{margin:0}}>
                Kup usługę, sprzedaj biznes, dodaj zapytanie ofertowe lub znajdź
                swoje następne zlecenie{" "}
                <RoughNotation
                  multiline={true}
                  color="#fff"
                  type="highlight"
                  show={true}
                >
                  bez ryzyka oszustwa i nieuczciwych kontrahentów
                </RoughNotation>
                .{" "}
              </Title>
              <br/>
              
              <Space direction="horizontal">
                <Link to="/account/register">
                  <Button type="primary" size="large">
                    ZAŁÓŻ DARMOWE KONTO
                  </Button>
                </Link>
              </Space>
              <br/>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <br />
              <Row gutter={12} justify="space-between" align="middle">
              <Col md={24}>
                  <Space size={30} direction="vertical">
                  <RecentAccountsList></RecentAccountsList>
                    Witaj MarketB2B.pl - nowym miejscu w sieci, gdzie wszystko
                    staje się prostsze – zamiast szukać wykonawcy, wybierasz
                    gotową usługę, a my dbamy o resztę . Połączyliśmy
                    bezpieczeństwo transakcji z wygodą marketplace, by dać Ci
                    pewność i oszczędność czasu. Kupuj usługi szybko, pewnie i
                    bez stresu.
                    <div>
                      <Space direction="vertical">
                        <a href="#looking-for-help">
                          Szukam pomocy przy swojej stronie
                        </a>
                        <a href="#looking-for-job">Szukam zlecenia</a>
                        <a href="#looking-for-website">Chcę kupić stronę</a>
                      </Space>
                    </div>
                  </Space>
                </Col>
          
                
              </Row>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
            <Title level={3}>
                <LikeTwoTone /> Sklep dla kwiaciarni w 14 dni{" "}
                <Tag color="cyan" style={{ fontWeight: 400 }}>
                  Oferta czasowa
                </Tag>
              </Title>
              <Link to="/marketplace/product/f55620d8-2a65-4b39-87f4-f8772a6c0ca5">
              <img
                src="/img/svg/flowers.svg"
                alt=""
                style={{ width: "100%" }}
              /></Link>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <Title level={3}>
                <LikeTwoTone /> Popraw swój WordPress{" "}
                <Tag color="cyan" style={{ fontWeight: 400 }}>
                  Polecamy wykonawców
                </Tag>
              </Title>
              <MarketplacePromotedProductsList />
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <br />
            <Space direction="vertical">
              <img src="/img/svg/app.svg" style={{ width: "100%" }} />
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row className="hide-on-mobile" justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <a name="looking-for-help"></a>
              <PromotedProducts />
            </Space>
            <br />
          </Col>

          <Col md={4}></Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <br />
            <Space direction="vertical">
              <img src="/img/svg/process.svg" style={{ width: "100%" }} />
            </Space>
          </Col>
          <Col md={8} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <Title level={3}>
                <BookTwoTone /> Zamów usługę od pewnego wykonawcy
              </Title>
              <Catalog />
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <a name="looking-for-job"></a>
              <Title level={3}>
                <SoundTwoTone /> Pozyskaj zlecenie
              </Title>
              <Card size="small" style={{ backgroundColor: "#1677ff" }}>
                <Space direction="horizontal">
                  Oferujesz usługi na najwyższym poziomie?{" "}
                  <Link to="/transactions">
                    <Button type="primary">Zacznij zdobywać klientów</Button>
                  </Link>
                </Space>
              </Card>
              <Inquiries limit={5} />
              <br />
              <center>
                <Link to={`/marketplace/inquiries`}>
                  Zobacz wszystkie zapytania ofertowe
                </Link>
              </center>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <a name="looking-for-website"></a>
              <Title level={3}>
                <ShopTwoTone /> Kup gotowy biznes
              </Title>
              <Card size="small" style={{ backgroundColor: "#1677ff" }}>
                <Space direction="horizontal">
                  Masz działający biznes lub stronę internetową na sprzedaż?{" "}
                  <Link to="/assets">
                    <Button type="primary">Wystaw swoją ofertę</Button>
                  </Link>
                </Space>
              </Card>

              <Assets />
              <br />
              <center>
                <Link to={`/marketplace/assets`}>Zobacz wszystkie oferty</Link>
              </center>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>

        <Divider></Divider>
        <Row justify="space-between" align="top">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Row gutter={12}>
              <Col md={12}>
                <Space direction="vertical">
                  <Title level={3}>Jak działa MarketB2B.PL?</Title>
                  <p>
                    MarketB2B.PL to zautomatyzowana platforma do zawierania
                    bezpiecznych transakcji internetowych “1 na 1” dla osób
                    prywatnych i firm.
                  </p>
                  <p>
                    W MarketB2B.PL tworzysz transakcje, w których ustalasz
                    warunki. Na te warunki Ty i Twój kontrahent musicie się
                    zgodzić aby transakcja przeszła do kolejnego etapu.
                    Następnie warunki muszą zostać wypełnione przez strony
                    transakcji.
                  </p>
                  <a href="/about">
                    <Button>Więcej o MarketB2B.PL</Button>
                  </a>
                </Space>
              </Col>

              <Col md={12}>
                <Space direction="vertical">
                  <Title level={3}>Najczęściej zadawane pytania</Title>
                  {questions.map((question) => (
                    <div key={question.key} style={{ marginBottom: "16px" }}>
                      <a
                        href=""
                        style={{ width: "300px", overflowX: "hidden" }}
                        type="link"
                        onClick={(e) => {
                          e.preventDefault();
                          setVisible(question.key);
                        }}
                      >
                        {question.label}
                      </a>
                      <Modal
                        title={question.label}
                        visible={visible === question.key}
                        onCancel={() => setVisible(null)}
                        footer={[
                          <Button key="close" onClick={() => setVisible(null)}>
                            Zamknij
                          </Button>,
                        ]}
                      >
                        {question.content}
                      </Modal>
                    </div>
                  ))}
                </Space>
              </Col>
            </Row>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="middle">
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space direction="vertical">
              <Row gutter={12}>
                <Col md={8}>
                  <Space direction="vertical">
                    <Title level={5} style={{ color: "#0E3D87" }}>
                      <ContactsTwoTone /> Gwarancja wykonania usługi
                    </Title>
                    <p>
                      MarketB2B.pl zapewnia, że każda ze stron dotrzyma swoich
                      zobowiązań. Nasze unikalne rozwiązania IT skutecznie
                      egzekwują realizację transakcji.
                    </p>
                  </Space>
                </Col>

                <Col md={8}>
                  <Space direction="vertical">
                    <Title level={5} style={{ color: "#0E3D87" }}>
                      <BuildTwoTone /> Uporządkowany proces
                    </Title>
                    <p>
                      Zapewniamy pełen porządek i przejrzystość na każdym etapie
                      współpracy. Wszystko odbywa się w odpowiedniej kolejności,
                      bez błędów i niedomówień.
                    </p>
                  </Space>
                </Col>

                <Col md={8}>
                  <Space direction="vertical">
                    <Title level={5} style={{ color: "#0E3D87" }}>
                      <DollarTwoTone /> Zabezpieczona płatność
                    </Title>
                    <p>
                      Dostawca zawsze otrzymuje swoje wynagrodzenie na czas.
                      Twoje środki są chronione, a wypłata gwarantowana.
                    </p>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <br />
        <Row
          style={{ backgroundColor: "#E8F0FC" }}
          justify="space-between"
          align="top"
        >
          <Col md={8}></Col>
          <Col md={8} style={{ padding: "15px 15px", textAlign: "center" }}>
            <p>
              {" "}
              W odpowiedzi na rosnący problem transakcji oszukańczych,
              stworzyliśmy platformę MarketB2B.PL – parasol ochronny dla
              transakcji B2B w Internecie.
            </p>
            <p>
              <strong>
                Minimalizujemy ryzyko chroniąc Twoje pieniądze przed oszustwami.
                Wybierz MarketB2B.PL i ciesz się spokojem podczas każdej
                transakcji online.
              </strong>
            </p>
            <div className="photo">
              <img src="/img/svg/3.svg" />
            </div>
            <div className="sign">
              <img src="/img/svg/4.svg" />
            </div>
            <div className="postpay">CEO MarketB2B.PL</div>
          </Col>
          <Col md={8}></Col>
        </Row>
      </Space>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default WelcomePage;
