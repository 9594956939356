import {
    Button,
    Modal,
    Row,
    Col,
    Typography,
    Space,
    Divider,
    Card,
    Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import { RoughNotation } from "react-rough-notation";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { fetchCategoryData } from "../../helpers/category";

const { Title } = Typography;

export const Catalog = () => {
    const [loadingCategoryGroup1Data, setLoadingCategoryGroup1Data] =
        useState(true);
    const [loadingCategoryGroup2Data, setLoadingCategoryGroup2Data] =
        useState(true);
    const [loadingCategoryGroup3Data, setLoadingCategoryGroup3Data] =
        useState(true);
    const [loadingCategoryGroup7Data, setLoadingCategoryGroup7Data] =
        useState(true);
    const [loadingCategoryGroup9Data, setLoadingCategoryGroup9Data] =
        useState(true);
    const [loadingCategoryGroup10Data, setLoadingCategoryGroup10Data] =
        useState(true);
    const [categoryGroup1Data, setCategoryGroup1Data] = useState([]);
    const [categoryGroup2Data, setCategoryGroup2Data] = useState([]);
    const [categoryGroup3Data, setCategoryGroup3Data] = useState([]);
    const [categoryGroup7Data, setCategoryGroup7Data] = useState([]);
    const [categoryGroup9Data, setCategoryGroup9Data] = useState([]);
    const [categoryGroup10Data, setCategoryGroup10Data] = useState([]);

    // Fetch category group 1 data
    useEffect(() => {
        if (loadingCategoryGroup1Data === true) {
            fetchCategoryData(1, setCategoryGroup1Data, setLoadingCategoryGroup1Data);
        }
    }, [loadingCategoryGroup1Data]);

    // Fetch category group 1 data
    useEffect(() => {
        if (loadingCategoryGroup2Data === true) {
            fetchCategoryData(2, setCategoryGroup2Data, setLoadingCategoryGroup2Data);
        }
    }, [loadingCategoryGroup2Data]);

    // Fetch category group 1 data
    useEffect(() => {
        if (loadingCategoryGroup2Data === true) {
            fetchCategoryData(3, setCategoryGroup3Data, setLoadingCategoryGroup3Data);
        }
    }, [loadingCategoryGroup3Data]);

    // Fetch category group 1 data
    useEffect(() => {
        if (loadingCategoryGroup7Data === true) {
            fetchCategoryData(7, setCategoryGroup7Data, setLoadingCategoryGroup7Data);
        }
    }, [loadingCategoryGroup7Data]);


    // Fetch category group 1 data
    useEffect(() => {
        if (loadingCategoryGroup9Data === true) {
            fetchCategoryData(9, setCategoryGroup9Data, setLoadingCategoryGroup9Data);
        }
    }, [loadingCategoryGroup9Data]);

    useEffect(() => {
        if (loadingCategoryGroup10Data === true) {
            fetchCategoryData(10, setCategoryGroup10Data, setLoadingCategoryGroup10Data);
        }
    }, [loadingCategoryGroup10Data]);

    return (<>
        <Space direction="vertical" style={{width:"100%"}}>

            <Row>
                <Col xs={24} md={12} >

                    <Title level={4}>Sklepy / Strony internetowe</Title>
                    <Spin spinning={loadingCategoryGroup1Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup1Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
                <Col xs={24} md={12} >

                    <Title level={4}>Aplikacje / API / Bazy danych</Title>
                    <Spin spinning={loadingCategoryGroup9Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup9Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12} >

                    <Title level={4}>Serwery / Cloud</Title>
                    <Spin spinning={loadingCategoryGroup2Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup2Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
                <Col xs={24} md={12} >

                    <Title level={4}>Dane / AI</Title>
                    <Spin spinning={loadingCategoryGroup10Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup10Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12} >

                    <Title level={4}>Grafika / Wizualizacje</Title>
                    <Spin spinning={loadingCategoryGroup7Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup7Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
                <Col xs={24} md={12} >

                    <Title level={4}>Reklama / Kampanie</Title>
                    <Spin spinning={loadingCategoryGroup3Data}>
                        <ul style={{ listStyleType: "none" }}>
                            {categoryGroup3Data.map((category) => (
                                <li key={category.id}>
                                    <Link to={`/marketplace/category/${category.uuid}`}>
                                        {category.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Spin>

                </Col>
            </Row>







        </Space>
    </>)
}

