import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import { useDispatch } from "react-redux";
import {
  Card,
  Col,
  Tabs,
  Space,
  Descriptions,
  Alert,
  Row,
  Tag,
  Table,
  Spin,
  message,
  Button,
  Tooltip,
  QRCode,
  Typography,
  Divider,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { formatPolishDate } from "../../helpers/date";
import { isTransactionInitiatedByLoggedAccount } from "../../helpers/account";
import {
  getAuthToken,
  isLogged,
  destroyAuthToken,
} from "../../helpers/account";
import { api } from "../../config/api";
import ActionBar from "./Actions/ActionBar";
import { getAccountTransactionRoleId } from "../../helpers/account";
import { useSelector } from "react-redux";
import {
  TeamOutlined,
  InboxOutlined,
  CreditCardOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  IssuesCloseOutlined,
  WarningOutlined,
  CopyOutlined,
  CalendarOutlined,
  BankOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import ProgressBar from "./ProgressBar/ProgressBar";
import NewItemForm from "../Item/NewItemForm";
import EditTransactionForm from "./Forms/EditTransactionForm";
import ChatWindow from "./Chat/ChatWindow";
import DeleteTransactionButton from "./Forms/DeleteTransactionButton";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
const { Title } = Typography;
function TransactionPage() {
  const [transactionPartData, setTransactionPartData] = useState(null);
  const [transactionInitiatorBankData, setTransactionInitiatorBankData] =
    useState(null);
  const [transactionCounterpartyBankData, setTransactionCounterpartyBankData] =
    useState(null);
  const [transactionData, setTransactionData] = useState(null);

  const [summaryData, setSummaryData] = useState(null);
  const [summaryTitleData, setSummaryTitleData] = useState(null);
  const [summaryAgreementData, setSummaryAgreementData] = useState(null);

  const [summaryDeliveryOnData, setSummaryDeliveryOnData] = useState(null);
  const [itemsData, setItemsData] = useState(null);

  const [paymentData, setPaymentData] = useState(null);
  const [paymentParticipationData, setPaymentParticipationData] =
    useState(null);

  const [
    paymentParticipationEscrowFeeData,
    setPaymentParticipationEscrowFeeData,
  ] = useState(null);

  const [deliveryData, setDeliveryData] = useState(null);
  const [returnData, setReturnData] = useState(null);
  const [regularConflictsData, setRegularConflictsData] = useState(null);
  const [mediatorConflictsData, setMediatorConflictsData] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  const { transactionUuid } = useParams();
  const { Text } = Typography;

  const handleDeleteItem = (uuid) => {
    setLoading(true);

    const apiUrl = `${api.host}/transactions/delete-item/${uuid}`;

    if (isLogged()) {
      // Fetch bank account data from the API
      fetch(apiUrl, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          message.success("Towar został usunięty z transakcji.");
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error while deleting transaction item data:", error);
        });
    }
  };

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${transactionUuid}`; // Update with your API endpoint

    // Fetch data from the API
    fetch(apiUrl, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTransactionPartData([
          {
            key: "12",
            label: "Inicjator",
            children: (
              <>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space>
                    <AvatarDisplay
                      src={data.transaction_initiator.photo}
                      account={data.transaction_initiator}
                    />
                    {data.transaction_initiator.fullname
                      ? data.transaction_initiator.fullname
                      : data.transaction_initiator.email}
                  </Space>
                  <Tag color="magenta">{data.initiator_role.name}</Tag>
                  <Tag color="geekblue">
                    {data.agreement_accepted_by_initiator === null
                      ? "Umowa oczekuje na akceptację"
                      : data.agreement_accepted_by_initiator === true
                      ? "Umowa zaakceptowana"
                      : "Umowa odrzucona"}
                  </Tag>
                  {isTransactionInitiatedByLoggedAccount(data) === true ? (
                    <Tag color="default">Ty</Tag>
                  ) : (
                    ""
                  )}
                </Space>
              </>
            ),
          },
          {
            key: "122",
            label: "Strona",
            children: (
              <>
                <Space direction="vertical">
                  <Space>
                    <AvatarDisplay
                      src={data.transaction_counterparty.photo}
                      account={data.transaction_counterparty}
                    />
                    {data.transaction_counterparty.fullname
                      ? data.transaction_counterparty.fullname
                      : data.transaction_counterparty.email}
                  </Space>
                  <Tag color="magenta">
                    {getAccountTransactionRoleId(
                      data,
                      data.transaction_counterparty
                    ) === 2
                      ? "Nabywca"
                      : "Sprzedawca"}
                  </Tag>
                  <Tag color="geekblue">
                    {data.agreement_accepted_by_counterparty === null
                      ? "Umowa oczekuje na akceptację"
                      : data.agreement_accepted_by_counterparty === true
                      ? "Umowa zaakceptowana"
                      : "Umowa odrzucona"}
                  </Tag>
                  {sessionStorage.getItem("uuid") ===
                  data.transaction_counterparty.uuid ? (
                    <Tag color="default">Ty</Tag>
                  ) : (
                    ""
                  )}
                </Space>
              </>
            ),
          },
        ]);

        setTransactionInitiatorBankData([
          {
            key: "asdasd2",
            label: "Numer konta do wypłaty środków",
            children: data.transaction_initiator_bank
              ? data.transaction_initiator_bank.number
              : "Nie podano",
          },
        ]);

        setTransactionCounterpartyBankData([
          {
            key: "23223dasdas",
            label: "Numer konta",
            children:
              data.transaction_counterparty_bank !== null
                ? data.transaction_counterparty_bank.number
                : "-",
          },
        ]);

        setSummaryTitleData([
          {
            key: "2",
            label: "Tytuł",
            children: data.title,
          },
        ]);
        setSummaryAgreementData([
          {
            key: "4",
            label: "Umowa",
            children: data.agreement,
          },
        ]);

        setSummaryDeliveryOnData([
          {
            key: "6",
            label: "Dostarczenie ustalone na dzień",
            children: formatPolishDate(data.agreement_items_delivery_at),
          },
          {
            key: "9",
            label: "Czas inspekcji (dni kalendarzowe)",
            children: `${data.inspection_period} dni`,
          },
        ]);

        setPaymentData([
          {
            key: "7",
            label: "Wartość przedmiotów w transakcji",
            children: formatPolishCurrency(data.escrow_amount),
          },
          {
            key: "822",
            label: "Kwota do wpłaty przez kupującego",
            children: formatPolishCurrency(data.escrow_amount_total),
          },
          {
            key: "8123123",
            label: "Kwota, którą otrzyma sprzedający",
            children: formatPolishCurrency(data.escrow_amount_release),
          },
        ]);

        setPaymentParticipationData([
          {
            key: "1ss9",
            label: "Koszty dostawy",
            children: formatPolishCurrency(data.shipping_method_fee),
          },
          {
            key: "912cxzc",
            label: "Płatnik",
            children: (
              <>
                <Space>
                  {data.shipping_fee_payer.fullname
                    ? data.shipping_fee_payer.fullname
                    : data.shipping_fee_payer.email}
                  <Tag color="magenta">
                    {data.shipping_fee_payer.uuid ===
                    data.transaction_initiator.uuid
                      ? data.initiator_role.name
                      : getAccountTransactionRoleId(
                          data,
                          data.transaction_initiator
                        ) === 1
                      ? "Nabywca"
                      : "Sprzedawca"}
                  </Tag>
                  {sessionStorage.getItem("uuid") ===
                  data.shipping_fee_payer.uuid ? (
                    <Tag color="default">Ty</Tag>
                  ) : (
                    ""
                  )}
                </Space>
              </>
            ),
          },
        ]);

        setPaymentParticipationEscrowFeeData([
          {
            key: "81212",
            label: "Prowizja dla MarketB2B.pl",
            children: (
              <>
                <Space>
                  {formatPolishCurrency(data.escrow_fee)}{" "}
                  {data.discount > 0 ? (
                    <Tag>Uwzględniony rabat: {data.discount * 100}%</Tag>
                  ) : (
                    ""
                  )}
                </Space>
              </>
            ),
          },
          {
            key: "81333kk",
            label: "Płatnik",
            children: (
              <>
                <Space>
                  {data.escrow_fee_payer.fullname
                    ? data.escrow_fee_payer.fullname
                    : data.escrow_fee_payer.email}
                  <Tag color="magenta">
                    {data.escrow_fee_payer.uuid ===
                    data.transaction_initiator.uuid
                      ? data.initiator_role.name
                      : getAccountTransactionRoleId(
                          data,
                          data.transaction_initiator
                        ) === 1
                      ? "Nabywca"
                      : "Sprzedawca"}
                  </Tag>
                  {sessionStorage.getItem("uuid") ===
                  data.escrow_fee_payer.uuid ? (
                    <Tag color="default">Ty</Tag>
                  ) : (
                    ""
                  )}
                </Space>
              </>
            ),
          },
        ]);

        setDeliveryData([
          {
            key: "6asd2",
            label: "Sposób dostawy",
            children: data.transaction_shipping_method.name,
          },
          {
            key: "6wqwe22",
            label: "Kod śledzenia przesyłki",
            children: data.shipping_tracking_code
              ? data.shipping_tracking_code
              : "Brak",
          },
        ]);

        setReturnData([
          {
            key: "6asdasd3gsf",
            label: "Sposób przekazania zwrotu",
            children: data.return_shipping_method.name,
          },
          {
            key: "6932kdkd",
            label: "Kod śledzenia przesyłki",
            children: data.inspection_items_refused_shipping_tracking_code
              ? data.inspection_items_refused_shipping_tracking_code
              : "Brak",
          },
        ]);

        setRegularConflictsData([
          {
            key: "15",
            label: "Transakcja obecnie w stanie konfliktu",
            children: <>{data.conflict ? "Tak" : "Nie"} </>,
          },
          {
            key: "15",
            label: "Powód wystąpienia konfliktu",
            children: (
              <>
                {data.conflict === true ||
                (data.conflict_resolved === true && data.conflict_reason)
                  ? data.conflict_reason
                  : "-"}{" "}
                {data.conflict_resolved ? (
                  <Tag color="green">Rozwiązany</Tag>
                ) : (
                  ""
                )}
              </>
            ),
          },
          {
            key: "115",
            label: "Przyjęte przez strony rozwiązanie konfliktu",
            children: (
              <>
                {data.transaction_last_conflict_resolution !== null
                  ? data.transaction_last_conflict_resolution
                      .transaction_last_conflict_resolution.name
                  : "-"}
              </>
            ),
          },
        ]);

        setMediatorConflictsData([
          {
            key: "15",
            label: "Transakcja w stanie konfliktu",
            children: <>{data.conflict_3rd_party_conflict ? "Tak" : "Nie"}</>,
          },
          {
            key: "15",
            label: "Powód konfliktu",
            children: (
              <>
                {data.conflict_3rd_party_conflict && data.conflict_reason
                  ? data.conflict_reason
                  : "-"}
              </>
            ),
          },
          {
            key: "11522",
            label: "Notatka od Sprzedawcy",
            children: (
              <>
                {data.inspection_items_refused_received_note !== null
                  ? data.inspection_items_refused_received_note
                  : "-"}
              </>
            ),
          },
        ]);

        setItemsData(
          data.items.map((item) => {
            return {
              uuid: item.uuid,
              key: item.uuid,
              name: item.name,
              price: formatPolishCurrency(item.price),
            };
          })
        );

        setTransactionData(data);

        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        setTimeout(() => {
          message.error("Dla bezpieczeństwa zostałeś wylogowany.");
          destroyAuthToken();
          window.location.href = "/transactions";
        }, 2000);
        setLoading(false); // Set loading to false even on error
      });
  }, [transactionUuid, transactionDataChanged]);

  useEffect(() => {
    setTabs([
      {
        key: "1",
        label: (
          <Tooltip title="Umowa">
            <CopyOutlined />
          </Tooltip>
        ),
        children: (
          <>
            <Space size={30} direction="vertical" style={{ width: "100%" }}>
              <Descriptions
                layout="vertical"
                bordered
                items={summaryAgreementData}
              />

              {transactionData ? (
                <>
                  <EditTransactionForm
                    transaction={transactionData}
                  ></EditTransactionForm>
                </>
              ) : (
                <></>
              )}
            </Space>
          </>
        ),
      },

      {
        key: "5",
        label: (
          <Tooltip title="Konflikty">
            <IssuesCloseOutlined />
          </Tooltip>
        ),
        children: (
          <>
            <Space direction="vertical" size={30} style={{ width: "100%" }}>
              <Descriptions
                title={
                  <span>
                    <IssuesCloseOutlined /> Konflikt prosty
                  </span>
                }
                layout="vertical"
                bordered
                items={regularConflictsData}
              />
              <Descriptions
                title={
                  <span>
                    <WarningOutlined /> Konflikt z użyciem mediatora
                  </span>
                }
                layout="vertical"
                bordered
                items={mediatorConflictsData}
              />
            </Space>
          </>
        ),
      },
    ]);
  }, [
    summaryData,
    paymentData,
    deliveryData,
    itemsData,
    mediatorConflictsData,
    regularConflictsData,
    returnData,
    transactionPartData,
    paymentParticipationData,
  ]);

  if (sessionStorage.getItem("authToken")) {
    return (
      <>
        <Spin spinning={loading}>
          <br />
          <Row style={{ padding: "0px 15px" }}>
            <Col md={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={12}>
                  <Col md={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {transactionData && transactionData.finished === false ? (
                        <ActionBar transaction={transactionData}></ActionBar>
                      ) : (
                        ""
                      )}
                      {transactionData && transactionData.finished === true ? (
                        <Alert
                          showIcon={true}
                          message={
                            transactionData.finished_transaction_status.name
                          }
                        ></Alert>
                      ) : (
                        ""
                      )}
                      <Title level={2}>
                        {transactionData && transactionData.title}
                      </Title>
                      <Card>
                        <ProgressBar
                          transaction={transactionData}
                        ></ProgressBar>
                      </Card>
                      <p>
                        <Space size={30}>
                          <p>
                            Umowa z dnia{" "}
                            {transactionData &&
                              formatPolishDate(transactionData.created_at)}
                          </p>
                          {transactionData ? (
                            <>
                              <EditTransactionForm
                                transaction={transactionData}
                              ></EditTransactionForm>
                            </>
                          ) : (
                            <></>
                          )}
                          <Link to="/transactions">Powrót</Link>
                        </Space>
                      </p>
                    </Space>
                  </Col>
                  <Col md={12}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Descriptions
                        layout="vertical"
                        bordered
                        items={transactionPartData}
                      />

                      <Title level={5}>Przedmioty transakcji</Title>
                      <Divider></Divider>
                      <Space
                        size={30}
                        direction="vertical"
                        style={{ width: "100%" }}
                      >
                        <Space style={{ float: "right" }}>
                          <NewItemForm
                            transaction={transactionData}
                          ></NewItemForm>
                        </Space>

                        <Table
                          bordered
                          title={() => "Przedmioty transakcji"}
                          dataSource={itemsData}
                          columns={[
                            {
                              title: "Nazwa",
                              dataIndex: "name",
                              key: "name",
                            },
                            {
                              title: "Wartość",
                              dataIndex: "price",
                              key: "price",
                            },
                            {
                              title: false,
                              dataIndex: "uuid",
                              key: "uuid",
                              render: (uuid) => (
                                <>
                                  <Button
                                    disabled={
                                      transactionData &&
                                      ((transactionData.agreement_accepted_by_initiator &&
                                        transactionData.agreement_accepted_by_counterparty) ||
                                        transactionData.finished)
                                    }
                                    key="delete"
                                    onClick={() => handleDeleteItem(uuid)}
                                  >
                                    Usuń
                                  </Button>
                                </>
                              ),
                            },
                          ]}
                          locale={{
                            emptyText: "Brak dodanych przedmiotów",
                          }}
                        />
                      </Space>

                      <Title level={5}>Dostawa, zwrot oraz inspekcja</Title>
                      <Divider></Divider>

                      <Descriptions
                        layout="vertical"
                        bordered
                        items={summaryDeliveryOnData}
                      />
                      <Descriptions
                        layout="vertical"
                        bordered
                        items={deliveryData}
                      />

                      <Descriptions
                        layout="vertical"
                        bordered
                        items={returnData}
                      />

                      <Title level={5}>Rozliczenia</Title>
                      <Divider></Divider>
                      <Descriptions
                        layout="vertical"
                        bordered
                        items={paymentData}
                      />

                      <Descriptions
                        layout="vertical"
                        bordered
                        items={paymentParticipationEscrowFeeData}
                      />

                      <Descriptions
                        layout="vertical"
                        bordered
                        items={paymentParticipationData}
                      />
                      <Descriptions
                        layout="vertical"
                        bordered
                        items={
                          transactionData
                            ? isTransactionInitiatedByLoggedAccount(
                                transactionData
                              )
                              ? transactionInitiatorBankData
                              : transactionCounterpartyBankData
                            : []
                        }
                      />
                      <br />
                    </Space>
                  </Col>
                  <Col md={12}>
                    <Card style={{ background: "#E8F0FC", padding: "15px" }}>
                      <Space direction="vertical" size={1}>
                        <Title style={{ margin: "0px" }} level={5}>
                          Komunikacja
                        </Title>
                        <ChatWindow
                          transactionUuid={
                            transactionData ? transactionData.uuid : null
                          }
                        ></ChatWindow>
                      </Space>
                    </Card>
                  </Col>
                </Row>

                <Card>
                  <Space
                    size={30}
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    <Tabs tabPosition="top" defaultActiveKey="1" items={tabs} />
                  </Space>
                </Card>
                {transactionData ? (
                  <>
                    {" "}
                    <DeleteTransactionButton
                      transactionUuid={transactionData.uuid}
                      onTransactionDeleted={() => {
                        setTimeout(() => {
                          window.location.href = "/transactions";
                        }, 2000);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
        </Spin>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default TransactionPage;
