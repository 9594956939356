import React, { useState, useEffect } from "react";
import { Alert, Col, Divider, Row, Space, Tag } from "antd"; // Make sure Link is imported from Ant Design
import { api } from "../../../../config/api"; // Import your API config here
import { getAuthToken } from "../../../../helpers/account"; // Import your auth helper
import { Link } from "react-router-dom";
import AvatarDisplay from "../AvatarDisplay";
import { PushpinTwoTone } from "@ant-design/icons";

const AccountShortInfo = () => {
    const [company, setCompany] = useState(true); // State to store the company name
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                const response = await fetch(
                    `${api.host}/accounts/${sessionStorage.getItem("uuid")}/public`,
                    {
                        method: "GET",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${getAuthToken()}`, // Use the token to authenticate
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch account data");
                }

                const data = await response.json();
                setCompany(data);
                setLoading(false); // Stop the loading state
            } catch (err) {
                setError(err.message); // Set error if request fails
                setLoading(false); // Stop the loading state
            }
        };

        fetchAccountData();
    }, []);

    return (

        <>
            <Row style={{ background: "#f5f5f5" }}>
                <Col md={4}></Col>
                <Col md={16} style={{ padding: "15px 15px" }}>
                    <Space>
                        <AvatarDisplay src={company?.photo} account={company} />
                        <div>
                            <Space direction="vertical">
                                <a target="_blank" href={`https://marketb2b.pl/profile/${sessionStorage.getItem("uuid")}`}>
                                    {company?.fullname || "Uzupełnij imię i nazwisko"}
                                </a>
                                <Tag>
                                    {company?.company_name || "Uzupełnij nazwę firmy"}
                                </Tag>
                            </Space>
                        </div>
                    </Space>{" "}
                </Col>
                <Col md={4}></Col>
            </Row>
        </>


    );
};

export default AccountShortInfo;
