import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import { RoughNotation } from "react-rough-notation";
import {
  Card, Space, Row, Col, Breadcrumb, Spin,
  Typography,
  Divider,
  Tag,
  Avatar
} from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import {
  HomeOutlined,
  SafetyCertificateTwoTone
} from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import PromotedProducts from "../Offer/PromotedProducts";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
const { Title } = Typography;

const MarketplaceProductsCategoriesPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const { categoryUuid } = useParams();

  useEffect(() => {
    const apiUrl = `${api.host}/products/category/${categoryUuid}`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [categoryUuid]);

  useEffect(() => {
    const apiUrl = `${api.host}/categories/${categoryUuid}`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setCategoryData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [categoryUuid]);

  return (

    <>
      <Helmet>
        <title>{categoryData.name}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>
                Usługi i produkty w kategorii{" "}
                “<span style={{ color: "#2D6BCE" }}>{categoryData.name}</span>”
              </Title>
              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/offers",
                    title: "Katalog ofert",
                  },
                  {
                    title: categoryData.name,
                  },
                ]}
              />
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}>

          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Spin spinning={loading}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {data.map((product) => (
                  <Card key={product.uuid}>
                    <Link to={`/marketplace/product/${product.uuid}`}>
                      <Title level={4}>
                        {product.name}
                      </Title>
                    </Link>
                    <Space direction="vertical">
                      <div>
                        Orientacyjny czas realizacji: {product.delivery_days} dni
                      </div>
                      <div>
                        Orientacyjna cena: <Tag color="gold-inverse">{formatPolishCurrency(product.price)}</Tag>
                      </div>
                      <div>
                        <Space>
                          Usługa oferowana przez <Link to={`/marketplace/product/${product.uuid}`}>{product.account_products.length} firmy</Link>
                        </Space>
                        {" "}
                        <Space>
                          <Avatar.Group
                            maxCount={7}
                            size="large"

                          >
                            {product.account_products.map((account, index) => (
                              <AvatarDisplay account={account.account} src={account.account.photo} size={32} />
                            ))}
                          </Avatar.Group>
                        </Space>

                      </div>
                    </Space>
                  </Card>
                ))}
              </Space>
            </Spin>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <PromotedProducts />
          </Col>
          <Col md={4}></Col>
        </Row>

      </Space>
    </>
  );
};

export default MarketplaceProductsCategoriesPage;
