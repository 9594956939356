import React, { useState } from "react";
import { Button, message, Spin, Modal } from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteAssetButton = ({ assetUuid, onDeleteSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteAsset = () => {
        setLoading(true);

        const apiUrl = `${api.host}/assets/${assetUuid}`;

        if (isLogged()) {
            fetch(apiUrl, {
                method: "DELETE",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to delete asset");
                    }
                    return response.json();
                })
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: "assetDataUpdated",
                        payload: {
                            assetDataChanged: new Date().getSeconds(),
                        },
                    });
                    message.success("Aktywo zostało pomyślnie usunięte.");
                    setDeleteModalOpen(false);
                    if (onDeleteSuccess) {
                        onDeleteSuccess();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error while deleting asset:", error);
                    message.error("Wystąpił błąd podczas usuwania aktywa.");
                });
        }
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleCancelDelete = () => {
        setDeleteModalOpen(false);
    };

    return (
        <>
            <Spin spinning={loading}>
                <Button danger onClick={handleOpenDeleteModal}>
                    <DeleteOutlined /> Usuń aktywo
                </Button>

                <Modal
                    title="Usuń aktywo"
                    open={deleteModalOpen}
                    onOk={handleDeleteAsset}
                    onCancel={handleCancelDelete}
                    okText="Usuń"
                    cancelText="Anuluj"
                >
                    <p>Czy na pewno chcesz usunąć to aktywo?</p>
                </Modal>
            </Spin>
        </>
    );
};

export default DeleteAssetButton;
