import React, { useState, useEffect } from "react";
import { Card, Row, Col, Spin, Typography, Space, Avatar, Divider } from "antd";
import { api } from "../../config/api"; // Adjust the import path to your API configuration
import { Link } from "react-router-dom";
import { LikeTwoTone } from "@ant-design/icons";
import { formatPolishCurrency } from "../../helpers/currency";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title, Text } = Typography;

const PromotedProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch promoted products
  useEffect(() => {
    const fetchPromotedProducts = async () => {
      try {
        const response = await fetch(`${api.host}/products/promoted`);
        if (!response.ok) {
          throw new Error("Failed to fetch promoted products.");
        }
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching promoted products:", error);
        setLoading(false);
      }
    };

    fetchPromotedProducts();
  }, []);

  return (
    <Space direction="vertical" className="hide-on-mobile">
      <Title level={3}>
        <LikeTwoTone /> Ulepsz swój WordPress
      </Title>
   
      <Row gutter={[16, 16]}>
        {products.map((product) => (
          <Col key={product.uuid} xs={24} sm={12} md={8} lg={6}>
            <Link to={`/marketplace/product/${product.uuid}`}>
              <Card
                hoverable
                cover={
                  <img
                    alt={product.name}
                    src={product.photo || "/img/product.jpg"}
                    style={{ height: 200, objectFit: "cover" }}
                  />
                }
              >
                <Card.Meta
                  title={product.name}
                  description={
                    <>
                      <Text strong>{formatPolishCurrency(product.price)}</Text>

                    </>
                  }
                />
                <Divider></Divider>
                <div>

                  Oferowane przez <Link to={`/marketplace/product/${product.uuid}`}>{product.account_products.length} firmy</Link>

                  {" "}
                  <Space>
                    <Avatar.Group
                      maxCount={7}
                      size="large"

                    >
                      {product.account_products.map((account, index) => (
                        <AvatarDisplay account={account.account} src={account.account.photo} size={32} />
                      ))}
                    </Avatar.Group>
                  </Space>

                </div>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Space>
  );
};

export default PromotedProducts;
