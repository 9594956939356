export function formatPolishCurrency(number) {
  // If the input is NaN or not a finite number, default to 0
  if (isNaN(number) || !isFinite(number)) {
    number = 0;
  }

  // Convert the number to a string with 2 decimal places
  const formattedNumber = Number(number).toFixed(2);

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = formattedNumber.split('.');

  // Add a space as a thousands separator
  const integerWithSpaces = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Combine the formatted integer and decimal parts with a comma
  const formattedCurrency = `${integerWithSpaces},${decimalPart}`;

  // Add the Polish currency symbol "zł" and return the result
  return `${formattedCurrency} zł`;
}
