export function formatPolishDate(inputDate, format = 'full') {
    // Return an empty string if the input date is empty, null, or invalid
    if (!inputDate || isNaN(new Date(inputDate).getTime())) {
      return '';
    }
  
    const months = [
      'styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec',
      'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'
    ];
  
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    if (format === 'full') {
      return `${day} ${month} ${year}, ${hours}:${minutes}`;
    }
    if (format === 'day') {
      return `${day} ${month} ${year}`;
    }
  }
  