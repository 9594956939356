import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import {
  Table,
  Card,
  Space,
  Row,
  Col,
  Tag,
  message,
  Typography,
  Breadcrumb,
  Divider,
  Avatar,
  Button,
  Modal,
} from "antd";
import { formatPolishDate } from "../../helpers/date";
import { formatPolishCurrency } from "../../helpers/currency";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../helpers/account";
import { api } from "../../config/api";
import { useSelector } from "react-redux";
import NewTransactionForm from "../../components/Transaction/Forms/NewTransactionForm";
import { HomeOutlined, MessageTwoTone } from "@ant-design/icons";
import AvatarDisplay from "../../components/Dashboard/Account/AvatarDisplay";
import ChatWindow from "../Transaction/Chat/ChatWindow";
const { Title, Text } = Typography;

function TransactionsPage() {
  const [data, setData] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  const handleOpenChat = (transaction) => {
    setSelectedTransaction(transaction);
    setIsChatVisible(true);
  };

  const handleCloseChat = () => {
    setIsChatVisible(false);
    setSelectedTransaction(null);
  };

  const columns = [
    {
      title: "Tytuł transakcji",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      sorter: true,
      render: (title, record) => (
        <>
          <Space direction="vertical">
            <Title level={5} style={{ margin: 0 }}>
              <u style={{ cursor: "pointer" }}>{title}</u>
              <Button
                type="link"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click
                  handleOpenChat(record);
                }}
              >
                <MessageTwoTone />
              </Button>
            </Title>
            
            <Space>
              <Avatar.Group>
                <AvatarDisplay
                  src={record.initiator_account.photo}
                  account={record.initiator_account}
                  size={32}
                />
                <AvatarDisplay
                  src={record.counterparty_account.photo}
                  account={record.counterparty_account}
                  size={32}
                />
              </Avatar.Group>
              <Tag>{record.counterparty_account.company_name}</Tag>
            </Space>
            <Tag color="gold-inverse">
              {formatPolishCurrency(record.escrow_amount)}
            </Tag>
          
          </Space>
        </>
      ),
    },
    {
      title: "Utworzona",
      dataIndex: "created_at",
      key: "created",
      render: (created_at) => formatPolishDate(created_at),
      className: "hide-on-mobile",
    },
    {
      title: "Status",
      dataIndex: "finished",
      key: "status",
      render: (finished) =>
        finished ? (
          <Tag color="magenta">Zakończona</Tag>
        ) : (
          <Tag color="green">Trwa</Tag>
        ),
    },
  ];

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions`; // Update with your API endpoint

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data); // Update the state with the fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/transactions";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [transactionDataChanged]);

  if (isLogged()) {
    return (
      <>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>Moje transakcje</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    title: "Moje transakcje",
                  },
                ]}
              />
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}></Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>

        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <Space size={30} direction="vertical" style={{ width: "100%" }}>
              <NewTransactionForm />
              <Card size="small">
                <Table
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        window.location.href = `/transaction/${record.uuid}`;
                      }, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  className="shadow"
                  columns={columns}
                  dataSource={data}
                  loading={loading}
                  locale={{
                    emptyText: "Jeszcze nie brałeś udziału w żadnej transakcji",
                  }}
                />
              </Card>
              <Modal
                title={
                  <>
                    <MessageTwoTone />{" "}
                    {selectedTransaction && selectedTransaction.title}
                  </>
                }
                visible={isChatVisible}
                onCancel={handleCloseChat}
                footer={null}
              >
                {selectedTransaction && (
                  <ChatWindow transactionUuid={selectedTransaction.uuid} />
                )}
              </Modal>
            </Space>
          </Col>
          <Col md={4}></Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default TransactionsPage;
