import React, { useState } from "react";
import { Button, message, Spin, Modal } from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteInquiryButton = ({ inquiryUuid }) => {
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteInquiry = () => {
    setLoading(true);

    const apiUrl = `${api.host}/inquiries/${inquiryUuid}`;

    if (isLogged()) {
      fetch(apiUrl, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete inquiry");
          }
          return response.json();
        })
        .then(() => {
          setLoading(false);
          dispatch({
            type: "inquiryDataUpdated",
            payload: {
              inquiryDataChanged: new Date().getSeconds(),
            },
          });
          message.success("Zapytanie zostało pomyślnie usunięte.");
          setDeleteModalOpen(false);
          setTimeout(() => {
            window.location.href = `/inquiries`;
          }, 2000);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error while deleting inquiry:", error);
          message.error("Wystąpił błąd podczas usuwania zapytania.");
        });
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button danger onClick={handleOpenDeleteModal}>
          <DeleteOutlined /> Usuń zapytanie
        </Button>

        <Modal
          title="Usuń zapytanie"
          open={deleteModalOpen}
          onOk={handleDeleteInquiry}
          onCancel={handleCancelDelete}
          okText="Usuń"
          cancelText="Anuluj"
        >
          <p>Czy na pewno chcesz usunąć to zapytanie?</p>
        </Modal>
      </Spin>
    </>
  );
};

export default DeleteInquiryButton;
