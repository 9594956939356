import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Avatar,
  Space,
  Divider,
} from "antd";
const { Title } = Typography;

const HelpBox = () => {
  return (
    <>
      <Card>
        <Title level={3}>
          Potrzebujesz pomocy?
        </Title>
        <p>
          {" "}
          Jesteśmy do Twojej dyspozycji od
          poniedziałku do piątku w godzinach 10:00-16:00.
        </p>
        <Divider></Divider>
        <Space direction="vertical">
         <Space>
         <Avatar size={64} src="/img/svg/3.svg" />
          Chętnie doradzę i odpowiem na Twoje pytania pod numerem
          +48 530 858 861.
         </Space>
        </Space>

      </Card>
    </>
  );
};

export default HelpBox;
