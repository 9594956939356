import React, { useState, useEffect } from 'react';
import { Alert } from 'antd'; // Make sure Link is imported from Ant Design
import { api } from '../../../../config/api'; // Import your API config here
import { getAuthToken } from '../../../../helpers/account'; // Import your auth helper
import { Link } from 'react-router-dom';

const AccountMissingInfo = () => {
  const [companyName, setCompanyName] = useState(true); // State to store the company name
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await fetch(`${api.host}/accounts/${sessionStorage.getItem("uuid")}/public`, {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`, // Use the token to authenticate
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch account data');
        }

        const data = await response.json();
        if (!data.company_name) {
            setCompanyName(false);
        }
        setLoading(false); // Stop the loading state
      } catch (err) {
        setError(err.message); // Set error if request fails
        setLoading(false); // Stop the loading state
      }
    };

    fetchAccountData();
  }, []);

  // Show error message if data fetch fails
  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  // If the company name is missing, show the alert with a proper Link
  if (!companyName) {
    return (
      <Alert
        message="Uzupełnij swój profil"
        description={
          <>
            Proszę uzupełnić dane firmy, aby Twoje dane były kompletne.{" "}
            <Link to="/account/data">Kliknij tutaj</Link> aby przejść do edycji.
            Jeśli oferujesz usługi <Link to="/account/offer">aktywuj swoją widoczność w katalogu</Link>.
          </>
        }
        type="warning"
        showIcon
      />
    );
  }

  return null; // If company name exists, return nothing
};

export default AccountMissingInfo;
