import React, { useState } from "react";
import {
  Card,
  Input,
  Space,
  Button,
  Row,
  Col,
  message,
  Steps,
  Spin,
  Checkbox,
} from "antd";
import {
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { isEmailValid, isPasswordSafe } from "../../helpers/validators";
import { Link } from "react-router-dom";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [regulationsAccepted, setRegulationsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirmPassword
  const [messageApi, contextHolder] = message.useMessage();

  const steps = [
    {
      title: "Utwórz konto",
    },
    {
      title: "Potwierdź e-mail",
    },
    {
      title: "Przeprowadź transakcję",
    },
  ];

  const handleRegulations = (e) => {
    setRegulationsAccepted(e.target.checked);
  };

  const handleRegister = () => {
    
    if (isEmailValid(email) !== true) {
      messageApi.open({
        type: "error",
        content: "Adres e-mail nie jest poprawny.",
      });
      return; // Exit the function early if email is not valid
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Hasła nie pasują do siebie. Spróbuj ponownie.",
      });
      return; // Exit the function early if passwords don't match
    }

    if (!isPasswordSafe(password)) {
      messageApi.open({
        type: "error",
        content:
          "Hasło musi mieć co najmniej 8 znaków i zawierać przynajmniej jedną wielką literę, jedną cyfrę i jeden znak specjalny (!@#$%^&*).",
      });
      return; // Exit the function early if password doesn't meet requirements
    }

    // Check if passwords match
    if (regulationsAccepted === false) {
      messageApi.open({
        type: "error",
        content: "Musisz zaakceptować postanowienia regulaminu usługi.",
      });
      return; // Exit the function early if passwords don't match
    }
    setLoading(true);
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts`;

    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      email,
      password,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 201) {
          messageApi.open({
            type: "success",
            content: "Konto zostało założone. Potwierdź podany e-mail.",
          });
          setCurrentStep(1);
        } else {
          messageApi.open({
            type: "error",
            content: "Na podany adres e-mail jest już zarejestrowane konto.",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Na podany adres e-mail jest już zarejestrowane konto.",
        });
      });
  };

  const handleVerification = () => {
    if (code == "") {
      messageApi.open({
        type: "error",
        content: "Nie przepisałeś kodu weryfikacyjnego.",
      });
      return; // Exit the function early if passwords don't match
    }

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/email-confirmation`;
    setLoading(true);
    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      email,
      code,
    };

    fetch(apiUrl, {
      method: "POST", // Use 'POST' if your API requires it
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          messageApi.open({
            type: "success",
            content: "Konto zostało zweryfikowane.",
          });
          setCurrentStep(1);
          setTimeout(() => {
            window.location.href = "/transactions";
          }, 2000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Niepoprawny kod weryfikacyjny. Spróbuj jeszcze raz.",
        });
      });
  };

  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      {contextHolder}
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Space size={30} direction="vertical">
          <Space size={10}>
            <h2 className="offer">Witaj w</h2>
            <h1 className="offer">MarketB2B.pl</h1>
          </Space>

          <Spin spinning={loading}>
            <Card
              title={
                <>
                  <UserOutlined /> Nowe Konto
                </>
              }
              extra={
                <Link to="/">
                  <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
                </Link>
              }
              style={{
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Space size={30} direction="vertical">
                <Steps size="small" current={currentStep} items={steps}></Steps>

                {currentStep == 0 ? (
                  <>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Adres e-mail"
                      prefix={<UserOutlined />}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Hasło"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Powtórz hasło"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)} // Set confirmPassword
                    />

                    <Checkbox onChange={handleRegulations}>
                      Potwierdzam, że zapoznałem/zapoznałam się z{" "}
                      <Link to="/regulations">regulaminem usługi MarketB2B.pl</Link>{" "}
                      oraz akceptuję jego warunki i postanowienia.
                    </Checkbox>

                    <Button type="primary" onClick={handleRegister}>
                      Utwórz konto
                    </Button>
                    <p>
                      <Link to="/transactions">Zaloguj się</Link> jeśli
                      posiadasz już konto .
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Przepisz otrzymany kod weryfikacyjny, który wysłaliśmy na
                      podany adres e-mail.
                    </p>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Kod weryfikacyjny"
                      prefix={<BlockOutlined />}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <Button type="primary" onClick={handleVerification}>
                      Zweryfikuj adres e-mail
                    </Button>
                  </>
                )}
              </Space>
            </Card>
          </Spin>
        </Space>
      </Col>
    </Row>
  );
};

export default RegisterPage;
