import React, { useState, useEffect, useRef } from "react";
import { api } from "../../config/api";
import { RoughNotation } from "react-rough-notation";
import {
  Card,
  Space,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Tag,
  Divider,
  Descriptions,
  Alert,
  Avatar,
  Button,
} from "antd";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged, getAuthToken } from "../../helpers/account";
import { Helmet } from "react-helmet-async";
import {
  CalendarTwoTone,
  HomeOutlined,
  EuroCircleTwoTone,
} from "@ant-design/icons";
import HelpBox from "../Offer/HelpBox";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
import NewTransactionForm from "../Transaction/Forms/NewTransactionForm";
import PromotedProducts from "../Offer/PromotedProducts";
const { Title } = Typography;

const MarketplaceProductPage = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [accounts, setAccounts] = useState([]);
  const { productUuid } = useParams();

  // Reference to the companies section
  const companiesSectionRef = useRef(null);

  const fetchProductData = async () => {
    try {
      const productResponse = await fetch(
        `${api.host}/products/${productUuid}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        }
      );
      const productData = await productResponse.json();
      setProduct(productData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchAssignedAccountsData = async () => {
    try {
      let apiEndpoint = `${api.host}/products/${productUuid}/accounts/public`;
      if (isLogged()) {
        apiEndpoint = `${api.host}/products/${productUuid}/accounts`;
      }
      const accountsResponse = await fetch(apiEndpoint, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      });
      const accountsData = await accountsResponse.json();
      setAccounts(accountsData);
    } catch (error) {
      console.error("Error fetching accounts data:", error);
    }
  };

  useEffect(() => {
    fetchProductData();
    fetchAssignedAccountsData();
  }, [productUuid]);

  const scrollToCompanies = () => {
    if (companiesSectionRef.current) {
      companiesSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space size={10} direction="vertical">
              <Title level={2}>{product.name}</Title>
              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/offers",
                    title: "Katalog ofert",
                  },
                  {
                    title: product.name,
                  },
                ]}
              />
              <img src="/img/svg/16.svg" />
              <Divider></Divider>
              <Space>
                Usługa oferowana przez{" "}
                <a href="#companies">{accounts.length} firmy</a>
              </Space>
              <Space>
                <Avatar.Group maxCount={7} size="large">
                  {accounts.map((account, index) => (
                    <AvatarDisplay
                      key={index}
                      account={account.account}
                      src={account.account.photo}
                      size={32}
                    />
                  ))}
                </Avatar.Group>
              </Space>
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}></Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Row gutter={12}>
              <Col md={16}>
                <Space direction="vertical">
                  <p
                    style={{ lineHeight: "185%" }}
                    dangerouslySetInnerHTML={{
                      __html: product.description
                        ? product.description.replace(/\n/g, "")
                        : "",
                    }}
                  ></p>
                </Space>
              </Col>
              <Col md={8}>
                <Space size={30} direction="vertical">
                  <Descriptions layout="vertical" bordered>
                    <Descriptions.Item
                      key="1"
                      label="Orientacyjna cena wykonania usługi"
                    >
                      <EuroCircleTwoTone />{" "}
                      <Tag color="gold-inverse">
                        {formatPolishCurrency(product.price)} brutto
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item
                      key="2"
                      label="Orientacyjny czas wykonania w dniach"
                    >
                      <CalendarTwoTone /> {product.delivery_days}
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={scrollToCompanies}
                  >
                    Zamów z gwarancją wykonania
                  </Button>
                  <HelpBox />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                {accounts.length === 0 ? (
                  <>
                    <Space size={2}>
                      Tej usługi jeszcze nikt w katalogu nie oferuje. Jeśli
                      jesteś dostawcą tego typu usług,{" "}
                      <Link to={`/account/register`}>zacznij</Link> oferować tę
                      usługę na MarketB2B.pl.
                    </Space>
                  </>
                ) : (
                  <>
                    <a name="companies" ref={companiesSectionRef}></a>
                    <Title level={4}>
                      Firmy oferujące ten produkt lub usługę
                    </Title>
                    <p>
                      Wybierz firmę, skontaktuj się, aby sprawdzić dostępność, a
                      następnie rozpocznij transakcję na platformie, ustalając
                      indywidualne warunki. Orientacyjny czas realizacji i koszt
                      to wartości zaproponowane przez MarketB2B.pl, które
                      pomogą Ci podjąć decyzję.
                    </p>
                    <Alert
                      type="warning"
                      message="Dla pełnego bezpieczeństwa zawsze przeprowadzaj transakcje przez platformę MarketB2B.pl. Dzięki temu unikniesz ryzyka straty czasu i pieniędzy, zyskując pewność i ochronę każdej transakcji."
                    ></Alert>
                    <Divider></Divider>
                  </>
                )}
                {accounts.map((account, index) => (
                  <Row key={index}>
                    <Col md={24}>
                      <Space>
                        <AvatarDisplay
                          account={account.account}
                          src={account.account.photo}
                          size={32}
                        />{" "}
                        {account.account.fullname}{" "}
                        <Tag>{account.account.company_name}</Tag>
                      </Space>
                      <Space>
                        {isLogged() ? (
                          <NewTransactionForm
                            isForVirtualItem = {true}
                            initiatorTransactionRoleId={{
                              value: 2,
                              label: "Nabywca",
                            }}
                            title={product.name}
                            email={account.account.email}
                          />
                        ) : (
                          <Link to="/transactions">Rozpocznij transakcję</Link>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))}
              </Space>
            </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 15px" }}>
            <PromotedProducts />
          </Col>
          <Col md={4}></Col>
        </Row>
      </Space>
    </>
  );
};

export default MarketplaceProductPage;
