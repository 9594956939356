import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Card,
    Space,
    Row,
    Col,
    Typography,
    Divider,
    List,
    Spin,
    Alert,
    Tag
} from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatPolishCurrency } from "../../helpers/currency";
import { formatPolishDate } from "../../helpers/date";
import { getAuthToken } from "../../helpers/account";
import {
    ApiTwoTone
} from "@ant-design/icons";
import NewTransactionForm from "../Transaction/Forms/NewTransactionForm";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";

const { Title, Text } = Typography;

const InquiryResponsesList = ({ inquiry }) => {
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState([]);
    const { inquiryUuid: routeInquiryUuid } = useParams();

    const finalInquiryUuid = (inquiry && inquiry.uuid) || routeInquiryUuid;

    // Redux state for listening to inquiry response events
    const inquiryResponseCreated = useSelector(
        (state) => state.inquiryResponseCreated
    );
    const inquiryResponseDeleted = useSelector(
        (state) => state.inquiryResponseDeleted
    );

    const fetchResponses = () => {
        if (!finalInquiryUuid) return;

        const apiUrl = `${api.host}/inquiries/${finalInquiryUuid}/responses`;

        setLoading(true);
        fetch(apiUrl, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `${getAuthToken()}`,
            }),

        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch inquiry responses");
                }
                return response.json();
            })
            .then((data) => {
                setResponses(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching responses:", error);
                setLoading(false);
            });
    };

    // Fetch responses on mount and when actions are dispatched
    useEffect(() => {
        fetchResponses();
    }, [finalInquiryUuid, inquiryResponseCreated, inquiryResponseDeleted]);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Row>
                <Col span={24}>
                    <Space direction="vertical" style={{width:"100%"}}>

                        <Title level={5}><ApiTwoTone /> Odpowiedzi na zapytanie</Title>
                        <Alert message="Rozpoczęcie transakcji uruchomi proces komunikacji z wykonawcą. Zyskasz możliwość kontaktu poprzez platformę oraz zabezpieczenie transakcji przed potencjalnymi oszustwami i nierzetelnymi kontrahentami.">

                        </Alert>

                        <Spin spinning={loading}>
                            {responses.length > 0 ? (
                                <List
                                    itemLayout="vertical"
                                    dataSource={responses}
                                    bordered={false}
                                    renderItem={(response) => (
                                        <List.Item>
                                            <Card>
                                                <Row gutter={16}>
                                                    <Col md={12}>
                                                        <Text strong>Cena:</Text>{" "}
                                                        {formatPolishCurrency(response.price)}
                                                    </Col>
                                                    <Col md={12}>
                                                        <Text strong>Czas dostawy:</Text>{" "}
                                                        {response.delivery_days} dni
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col md={12}>
                                                        <Text strong>Firma:</Text>{" "}
                                                        {response.account.fullname} ({response.account.company_name})
                                                    </Col>
                                                    <Col md={12}>
                                                        <Text strong>E-mail:</Text>{" "}
                                                        {response.account.email}
                                                    </Col>

                                                </Row>
                                                <Row gutter={16}>
                                                    <Col md={12}>
                                                        <Text strong>Telefon:</Text>{" "}
                                                        {response.account.mobile ? <><a href={`tel:${response.account.mobile}`}>{response.account.mobile}</a></> : "-"}
                                                    </Col>
                                                    <Col md={12}>
                                                        <Text strong>Dodano:</Text>{" "}
                                                        {formatPolishDate(response.created_at)}
                                                    </Col>
                                                </Row>
                                                <Divider />
                                                <Space direction="vertical">
                                                    <Text>{response.message}</Text>
                                                    <Space><AvatarDisplay src={response.account.photo} size={32} /> {response.account.fullname} <Tag>{response.account.company_name}</Tag></Space>
                                                    <NewTransactionForm
                                                        initiatorTransactionRoleId={{
                                                            value: 2,
                                                            label: "Nabywca",
                                                        }}
                                                        title={inquiry.name}
                                                        email={response.account.email}
                                                    />

                                                </Space>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            ) : (
                                <Text>Brak odpowiedzi na to zapytanie.</Text>
                            )}
                        </Spin>

                    </Space>
                </Col>
            </Row>
        </Space>
    );
};

export default InquiryResponsesList;
