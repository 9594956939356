import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import LoginPage from "../../../Login/LoginPage";
import { Table, Card, Space, Row, Col, Breadcrumb, Tag, Divider, Typography, Alert } from "antd";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { PushpinTwoTone } from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

const categoryColumns = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/category/${record.uuid}`}>{name}</Link>
            </>
        ),
    },
];

const accountProductColumns = [
    {
        title: "Produkt / Usługa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/product/${record.product.uuid}`}>{record.product.name}</Link>
            </>
        ),
    },
];

function AccountCategoriesPage() {
    const [data, setData] = useState([]);
    const [accountProducts, setAccountProducts] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        if (isLogged()) {
            fetch(`${api.host}/categories`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching categories data:", error);
                    setLoading(false);
                });

            fetch(`${api.host}/accounts/products`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setAccountProducts(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching account products data:", error);
                    setLoading(false);
                });
        }
    }, []);

    if (isLogged()) {
        return (
            <>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "15px 15px" }}>
                        <Space direction="vertical">
                            <Title level={2}>Moja oferta</Title>
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Moja oferta",
                                    },
                                ]}
                            />
                        </Space>
                    </Col>
                    <Col md={4} style={{ textAlign: "center" }}></Col>
                    <Col md={4}></Col>
                </Row>
                <Divider></Divider>

                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>

                            <Card size="small">

                                <a target="_blank" href={`https://marketb2b.pl/profile/${sessionStorage.getItem("uuid")}`}>
                                    <PushpinTwoTone /> Publiczny profil
                                </a>

                                <Divider></Divider>
                                <Table
                                    pagination={{ pageSize: 5 }}
                                    className="shadow"
                                    columns={accountProductColumns}
                                    dataSource={accountProducts}
                                    loading={loading}
                                    locale={{
                                        emptyText: "Nie oferujesz żadnych produktów i usług",
                                    }}
                                />
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>

                <Divider></Divider>


                <Row>
                    <Col md={4}></Col>
                    <Col md={16} style={{ padding: "0px 15px" }}>
                        <Space size={30} direction="vertical" style={{ width: "100%" }}>
                            <Alert
                                showIcon
                                message="Wybierz kategorie usług, które oferujesz, a następnie aktywuj swoje oferty w katalogu marketB2B.pl, aby zainteresowani klienci mogli je u Ciebie zamawiać. Proces aktywacji jest całkowicie darmowy. Oferty automatycznie pojawią się również na Twoim profilu publicznym.">

                            </Alert>
                            <Card size="small">
                                <Table
                                    pagination={{ pageSize: 5 }}
                                    className="shadow"
                                    columns={categoryColumns}
                                    dataSource={data}
                                    loading={loading}
                                    locale={{
                                        emptyText: "Brak kategorii usług",
                                    }}
                                />
                            </Card>
                        </Space>
                    </Col>
                    <Col md={4}></Col>
                </Row>


            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountCategoriesPage;
