import React from "react";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";

const AvatarDisplay = ({ src, size = 64, alt = "", tooltip = "", account = "" }) => {
  return (
    <Tooltip title={account.company_name || account.fullname}>
      <Link to={`/profile/${account.uuid}`}>
        <Avatar
          src={src}
          size={size}
          icon={!src && <img src="/img/default-avatar-1.png" />} // Use placeholder icon if no avatar
          alt={alt}
          style={{
            backgroundColor: src ? "transparent" : "#fff",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        />
      </Link>
    </Tooltip>
  );
};

// Define prop types
AvatarDisplay.propTypes = {
  src: PropTypes.string,        // URL of the avatar image
  size: PropTypes.oneOfType([
    PropTypes.number,           // Numeric size in pixels
    PropTypes.oneOf(["large", "small", "default"]), // Predefined sizes for Ant Design Avatar
  ]),
  alt: PropTypes.string,        // Alt text for the image
  tooltip: PropTypes.string,    // Tooltip text when hovering over the avatar
};

// Default props
AvatarDisplay.defaultProps = {
  src: null,                    // Default to no avatar image
  size: 64,                     // Default size
  alt: "User Avatar",           // Default alt text
  tooltip: "User Avatar",       // Default tooltip text
};

export default AvatarDisplay;
