import React, { useState } from "react";
import { Button, message, Spin, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { api } from "../../../config/api";
import { getAuthToken } from "../../../helpers/account";

const DeleteTransactionButton = ({ transactionUuid, onTransactionDeleted }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDelete = async () => {
        setLoading(true);

        try {
            const response = await fetch(`${api.host}/transactions/${transactionUuid}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to delete transaction");
            }

            message.success("Transakcja została usunięta.");
            setLoading(false);
            setIsModalOpen(false);

            // Optional callback to update the parent component
            if (onTransactionDeleted) {
                onTransactionDeleted(transactionUuid);
            }
        } catch (error) {
            console.error("Error deleting transaction:", error);
            message.error(error.message || "Transakcja nie może być usunięta.");
            setLoading(false);
        }
    };

    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    return (
        <>
            <Spin spinning={loading}>
                <Button danger icon={<DeleteOutlined />} onClick={showModal}>
                    Usuń transakcję
                </Button>
            </Spin>

            <Modal
                title="Potwierdź usunięcie transakcji"
                open={isModalOpen}
                onOk={handleDelete}
                onCancel={handleCancel}
                okText="Usuń"
                cancelText="Anuluj"
                okButtonProps={{ danger: true }}
            >
                <p>Jesteś pewien? Usunięcie transakcji jest możliwe tylko w przypadku, gdy została zakończona lub nie wpłacono depozytu. Operacja ta jest nieodwracalna.</p>
            </Modal>
        </>
    );
};

export default DeleteTransactionButton;
