import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import { RoughNotation } from "react-rough-notation";
import { Card, Space, Row, Col, Breadcrumb, Typography, Tag, Divider, Descriptions, Alert } from "antd";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged, getAuthToken } from "../../helpers/account";
import { Helmet } from "react-helmet-async";
import {
  CalendarTwoTone,
  HomeOutlined,
  EuroCircleTwoTone,
  ClockCircleTwoTone
} from "@ant-design/icons";
import HelpBox from "../Offer/HelpBox";
import DeleteInquiryButton from "./Forms/DeleteInquiryButton";
import EditInquiryForm from "./Forms/EditInquiryForm";
import { formatPolishDate } from "../../helpers/date";
import InquiryResponsesList from "./InquiryResponsesList";
const { Title } = Typography;

const InquiryPage = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [accounts, setAccounts] = useState([]);
  const { inquiryUuid } = useParams();

  const fetchProductData = async () => {
    try {
      const productResponse = await fetch(
        `${api.host}/inquiries/${inquiryUuid}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),

        }
      );
      const productData = await productResponse.json();
      setProduct(productData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };


  useEffect(() => {
    fetchProductData();
  }, [inquiryUuid]);

  return (

    <>
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Space direction="vertical">
              <Title level={2}>
                {product.name}
              </Title>

              <Breadcrumb
                items={[
                  {
                    href: "/",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/inquiries",
                    title: "Moje zapytania",
                  },
                  {
                    title: product.name,
                  },
                ]}
              />
              {
                !product.is_active ? (
                  <Tag color="magenta">Nieaktywne</Tag>
                ) : (
                  <Tag color="green">Publiczne</Tag>
                )
              }
            </Space>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}>

          </Col>
          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>


            <Row gutter={12}>
              <Col md={24}>
                <Space direction="vertical">
                  <Space>


                    <EditInquiryForm
                      inquiryUuid={product.uuid}
                      onInquiryUpdated={() => {
                        console.log("Inquiry updated successfully!");
                        // Optionally refresh data in the parent component
                      }}
                    />
                    <DeleteInquiryButton inquiryUuid={product.uuid} />

                  </Space>
                      <br/>
                  <Descriptions layout="vertical" bordered>
                    <Descriptions.Item
                      key="1"
                      label={
                        <>
                          Budżet
                        </>
                      }
                    >
                      <EuroCircleTwoTone /> {formatPolishCurrency(product.budget)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      key="2"
                      label={
                        <>
                          Akceptowalny czas realizacji
                        </>
                      }
                    >
                      <ClockCircleTwoTone /> {product.delivery_days}
                    </Descriptions.Item>
                    <Descriptions.Item
                      key="2"
                      label={
                        <>
                          Akceptowalny termin realizacji
                        </>
                      }
                    >
                      <CalendarTwoTone /> {formatPolishDate(product.valid_to)}
                    </Descriptions.Item>
                  </Descriptions>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: product.message
                        ? product.message.replace(/\n/g, "<br>")
                        : "",
                    }}
                  ></p>
                </Space>
              </Col>

            </Row>


          </Col>
          <Col md={4}>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "15px 15px" }}>
            <Card>
                <InquiryResponsesList inquiry={product} />
            </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Space>

    </>
  );
};

export default InquiryPage;
