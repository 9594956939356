import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Divider,
  Modal,
  Input,
  message,
  Spin,
  Upload,
  Select,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const EditAssetForm = ({ assetUuid, onAssetUpdated }) => {
  const [editAssetModalOpen, setEditAssetModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [assetPreview, setAssetPreview] = useState(null);
  const [assetFile, setAssetFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    type: "",
    asset_type_id: null,
    customers: null,
    ttm_revenue: null,
    ttm_profit: null,
    avg_monthly_visitors: null,
    is_active: false,
    target_segment: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (assetUuid) {
      fetchAssetData();
    }
  }, [assetUuid]);

  const fetchAssetData = async () => {
    try {
      const response = await fetch(`${api.host}/assets/${assetUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch asset data.");
      }

      const assetData = await response.json();
      setFormData(assetData);
      setAssetPreview(assetData.photo || null);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching asset data:", error);
      message.error("Błąd podczas pobierania danych aktywa.");
      setLoading(false);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUpdateAsset = async () => {
    try {
      setUploading(true);

      let uploadedPhotoUrl = assetPreview;
      if (assetFile) {
        const base64Image = await convertFileToBase64(assetFile);

        const response = await fetch(`https://im27ci1n8g.execute-api.eu-central-1.amazonaws.com/v1/upload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ image: base64Image }),
        });

        if (!response.ok) {
          throw new Error("Błąd przesyłania zdjęcia.");
        }

        const data = await response.json();
        uploadedPhotoUrl = data.publicUrl;
      }

      const assetData = {
        ...formData,
        photo: uploadedPhotoUrl,
      };

      const response = await fetch(`${api.host}/assets/${assetUuid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        },
        body: JSON.stringify(assetData),
      });

      if (!response.ok) {
        throw new Error("Błąd podczas aktualizacji aktywa.");
      }

      const updatedAsset = await response.json();

      dispatch({
        type: "assetsDataChanged",
        payload: { assetsDataChanged: new Date().getSeconds() },
      });

      message.success("Aktywo zostało zaktualizowane.");
      setEditAssetModalOpen(false);
      if (onAssetUpdated) {
        onAssetUpdated(updatedAsset);
      }
    } catch (error) {
      console.error("Error updating asset:", error);
      message.error("Błąd podczas aktualizacji aktywa.");
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) {
      message.error("Nie udało się załadować pliku. Spróbuj ponownie.");
      return;
    }
    setAssetFile(file);
    const reader = new FileReader();
    reader.onload = () => setAssetPreview(reader.result);
    reader.onerror = () => {
      message.error("Błąd podczas generowania podglądu zdjęcia.");
    };
    reader.readAsDataURL(file);
  };

  const handleCancel = () => {
    setEditAssetModalOpen(false);
  };

  return (
    <>
      <Button onClick={() => setEditAssetModalOpen(true)} type="primary">
        Edytuj aktywo
      </Button>

      <Modal
        title="Edytuj aktywo"
        open={editAssetModalOpen}
        onOk={handleUpdateAsset}
        onCancel={handleCancel}
        okText="Zaktualizuj"
        cancelText="Anuluj"
        width={1000}
      >
        <Spin spinning={loading || uploading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <label>Typ aktywa:</label>
            <Select
              placeholder="Wybierz typ aktywa"
              onChange={(value) =>
                setFormData({ ...formData, asset_type_id: value })
              }
              value={formData.asset_type_id}
              style={{ width: "100%" }}
            >
              <Option value={1}>SaaS</Option>
              <Option value={2}>E-commerce</Option>
              <Option value={3}>Strona/blog</Option>
              <Option value={4}>Profil w mediach społecznościowych</Option>
            </Select>
            <label>Nazwa:</label>
            <Input
              placeholder="Sklep z odzieżą"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
            />
            <label>Opis:</label>
            <TextArea
              placeholder="Opis aktywa..."
              rows={8}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              value={formData.description}
            />
            <label>Cena:</label>
            <Input
              placeholder="10000.00"
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, price: e.target.value })
              }
              value={formData.price}
            />
            <Divider />
            <label>Liczba klientów:</label>
            <Input
              placeholder="1000"
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, customers: e.target.value })
              }
              value={formData.customers}
            />
            <label>Odwiedzający miesięcznie:</label>
            <Input
              placeholder="30000"
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, avg_monthly_visitors: e.target.value })
              }
              value={formData.avg_monthly_visitors}
            />
            <Divider />
            <label>Przychód z ostatnich 12 miesięcy:</label>
            <Input
              placeholder="199000"
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, ttm_revenue: e.target.value })
              }
              value={formData.ttm_revenue}
            />
            <label>Zysk z ostatnich 12 miesięcy:</label>
            <Input
              placeholder="100000"
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, ttm_profit: e.target.value })
              }
              value={formData.ttm_profit}
            />
            <label>Segment docelowy:</label>
            <Input
              placeholder="B2B, B2C"
              onChange={(e) =>
                setFormData({ ...formData, target_segment: e.target.value })
              }
              value={formData.target_segment}
            />
            <Space>
              <label>Czy aktywne w marketplace?</label>
              <Switch
                checked={formData.is_active}
                onChange={(checked) =>
                  setFormData({ ...formData, is_active: checked })
                }
              />
            </Space>
            <Divider />
            <Upload
              accept="image/*"
              beforeUpload={() => false}
              onChange={handleFileChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Wybierz zdjęcie</Button>
            </Upload>
            {assetPreview && (
              <img
                src={assetPreview}
                alt="Asset preview"
                style={{ width: 100, height: 100, borderRadius: "5%" }}
              />
            )}
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditAssetForm;
