import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import {
  Table,
  Card,
  Space,
  Row,
  Col,
  Tag,
  message,
  Button,
  Select,
} from "antd";
import { formatPolishDate } from "../../helpers/date";
import { formatPolishCurrency } from "../../helpers/currency";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../helpers/account";
import { api } from "../../config/api";
import { DollarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function TransactionsAdminPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  const columns = [
    {
      title: "Tytuł transakcji",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      sorter: true,
      render: (title) => (
        <>
          <u style={{ cursor: "pointer" }}>{title}</u>
        </>
      ),
    },
    {
      title: "Utworzona",
      dataIndex: "created_at",
      key: "created",
      render: (created_at) => formatPolishDate(created_at),
      className: "hide-on-mobile",
    },
    {
      title: "Wartość",
      dataIndex: "escrow_amount",
      key: "value",
      render: (escrow_amount) => formatPolishCurrency(escrow_amount),
    },
    {
      title: "Konflikt",
      dataIndex: "conflict",
      key: "conflict",
      render: (conflict) =>
        conflict ? (
          <Tag color="magenta">Tak</Tag>
        ) : (
          <Tag color="green">Nie</Tag>
        ),
    },
    {
      title: "Status",
      render: (record) =>
        record.finished ? (
          <Tag color="magenta">Zakończona</Tag>
        ) : (
          <Tag color="green">Trwa</Tag>
        ),
    },
    {
      title: "Depozyt wpłacony",
      render: (record) =>
        record.escrow_received ? (
          <Tag color="green">Tak</Tag>
        ) : (
          <Tag color="magenta">Nie</Tag>
        ),
    },
    {
      title: "Depozyt wypłacony",
      render: (record) =>
        record.escrow_released ? (
          <Tag color="green">Tak</Tag>
        ) : (
          <Tag color="magenta">Nie</Tag>
        ),
    },
    {
      title: "Depozyt zwrócony",
      render: (record) =>
        record.escrow_refunded ? (
          <Tag color="green">Tak</Tag>
        ) : (
          <Tag color="magenta">Nie</Tag>
        ),
    },
    {
      title: "Akcje",
      render: (record) => (
        <>
          <Space direction="vertical">
            {record.escrow_received === false ? (
              <>
                {" "}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleDepositPayment(record)}
                >
                  Wpłać depozyt - przypomnienie
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleConfirmDepositPayment(record)}
                >
                  Depozyt wpłacony - powiadomienie
                </Button>
              </>
            ) : (
              <></>
            )}

            {record.items_sent === false ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleSendAgreementItem(record)}
              >
                Wyślij przedmiot umowy - przypomnienie
              </Button>
            ) : (
              ""
            )}

            {record.items_received !== true ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleReceiveAgreementItem(record)}
              >
                Odbierz przedmiot umowy - przypomnienie
              </Button>
            ) : (
              ""
            )}

            {record.inspection_items_refused_sent !== true &&
            record.inspection_completed_successfully === false ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleSendItemReturn(record)}
              >
                Wyślij zwrot przedmiotu - przypomnienie
              </Button>
            ) : (
              ""
            )}

            {record.escrow_released === false ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleEscrowReleased(record)}
              >
                Depozyt wypłacony
              </Button>
            ) : (
              ""
            )}

            {record.escrow_refunded === false ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleEscrowRefuned(record)}
              >
                Depozyt zwrócony
              </Button>
            ) : (
              ""
            )}
          </Space>
        </>
      ),
    },
  ];

  // Function to handle deposit payment
  const handleDepositPayment = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/notifications/load-escrow/${transaction.uuid}`;
    const jsonData = {};

    // Fetch data from the API
    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  // Function to handle confirming deposit payment
  const handleConfirmDepositPayment = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/admin/transactions/${transaction.uuid}`;
    const jsonData = {
      escrow_received: true,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  const handleEscrowReleased = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/admin/transactions/${transaction.uuid}`;
    const jsonData = {
      escrow_released: true,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  const handleEscrowRefuned = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/admin/transactions/${transaction.uuid}`;
    const jsonData = {
      escrow_refunded: true,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  // Function to handle sending the item of the agreement
  const handleSendAgreementItem = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/notifications/send-items/${transaction.uuid}`;
    const jsonData = {};

    // Fetch data from the API
    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  // Function to handle receiving the item of the agreement
  const handleReceiveAgreementItem = (transaction) => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/notifications/receive-items/${transaction.uuid}`;
    const jsonData = {};

    // Fetch data from the API
    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        message.success("Transakcja została zaktualizowana");
        setLoading(false);
        // Update your state or perform other actions as needed
      })
      .catch((error) => {
        message.error("Wystąpiły błędy podczas zapisu stanu transakcji.");
        setLoading(false);
      });
  };

  // Function to handle sending the item return
  const handleSendItemReturn = () => {
    // Make an API request to send the item return
    // Use Axios or the fetch API as you did in useEffect
    // Update state or show a success message as needed
  };

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/admin/transactions`; // Update with your API endpoint

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data); // Update the state with the fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/transactions";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [transactionDataChanged]);

  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            minHeight: "100vh",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction="vertical">
              <Card
                title={
                  <>
                    <DollarOutlined /> Aktywne transakcje w systemie
                  </>
                }
              >
                <Space size={30} direction="vertical">
                  <Table
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {}, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                      };
                    }}
                    className="shadow"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    locale={{
                      emptyText: "Brak aktywnych transakcji w systemie.",
                    }}
                  />
                </Space>
              </Card>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default TransactionsAdminPage;
