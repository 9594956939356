const initialState = false;

const ACTIONS = {
    INQUIRY_RESPONSE_DELETED: 'inquiryResponseDeleted'
};

export default function filtersReducer(state = initialState, action) {
    if (action.type === ACTIONS.INQUIRY_RESPONSE_DELETED) {
        return action.payload.inquiryResponseDeleted;
    } else {
        return state;
    }
}