import React, { useState } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  InputNumber,
  Checkbox,
} from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";

const { TextArea } = Input;

const NewInquiryResponseForm = ({ inquiryUuid, onResponseCreated }) => {
  const [newResponseModalOpen, setNewResponseModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    price: "",
    message: "",
    delivery_days: "",
    is_anonymous: false, // Added for anonymous responses
  });
  const dispatch = useDispatch();

  const handleCreateNewResponse = () => {
    if (validateFormData()) {
      setLoading(true);

      const apiData = {
        price: formData.price,
        message: formData.message,
        delivery_days: formData.delivery_days,
        is_anonymous: formData.is_anonymous, // Include is_anonymous in API data
      };

      const apiUrl = `${api.host}/inquiries/${inquiryUuid}/responses`;

      if (isLogged()) {
        fetch(apiUrl, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to create inquiry response");
            }
            return response.json();
          })
          .then(() => {
            setLoading(false);
            dispatch({
              type: "inquiryResponseCreated",
              payload: {
                inquiryResponseCreated: new Date().getSeconds(),
              },
            });
            message.success("Odpowiedź została pomyślnie utworzona.");
            setNewResponseModalOpen(false);
            setFormData({
              price: "",
              message: "",
              delivery_days: "",
              is_anonymous: false, // Reset is_anonymous
            });
            if (onResponseCreated) {
              onResponseCreated();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while creating inquiry response:", error);
            message.error("Wystąpił błąd podczas tworzenia odpowiedzi.");
          });
      }
    }
  };

  const validateFormData = () => {
    if (!formData.price || !formData.message || !formData.delivery_days) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    if (isNaN(parseFloat(formData.price))) {
      message.error("Cena musi być liczbą.");
      return false;
    }
    if (isNaN(parseInt(formData.delivery_days))) {
      message.error("Czas dostawy musi być liczbą.");
      return false;
    }
    return true;
  };

  const handleNewResponse = () => {
    setNewResponseModalOpen(true);
  };

  const handleCancelNewResponse = () => {
    setNewResponseModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button onClick={handleNewResponse} type="primary">
          Zaoferuj swoją usługę
        </Button>

        <Modal
          title="Nowa odpowiedź na zapytanie"
          open={newResponseModalOpen}
          onOk={handleCreateNewResponse}
          onCancel={handleCancelNewResponse}
          okText="Dodaj"
          cancelText="Anuluj"
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <InputNumber
              placeholder="Cena"
              min={1}
              onChange={(value) => setFormData({ ...formData, price: value })}
              value={formData.price}
            />
            <TextArea
              placeholder="Treść odpowiedzi"
              rows={4}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              value={formData.message}
            />
            <InputNumber
              placeholder="Czas dostawy (dni)"
              min={1}
              onChange={(value) =>
                setFormData({ ...formData, delivery_days: value })
              }
              value={formData.delivery_days}
            />
         
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default NewInquiryResponseForm;