import { api } from "../config/api";

export const fetchCategoryData = (group, setData, setLoading) => {
    const apiUrl = `${api.host}/categories/group/${group}`;

    fetch(apiUrl, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };