import { combineReducers } from 'redux'

import transactionDataChanged from './features/transaction/transactionDataChanged';
import bankAccountsListUpdated from './features/bank/bankAccountsListUpdated';
import transactionCreated from './features/transaction/transactionCreated'
import accountDataChanged from './features/account/accountDataChanged';
import inquiryDataChanged from './features/inquiry/inquiryDataChanged';
import inquiryResponseCreated from './features/inquiry/inquiryResponseCreated'
import inquiryResponseDeleted from './features/inquiry/inquiryResponseDeleted'

const rootReducer = combineReducers({
    transactionDataChanged: transactionDataChanged,
    bankAccountsListUpdated: bankAccountsListUpdated,
    transactionCreated: transactionCreated,
    accountDataChanged: accountDataChanged,
    inquiryDataChanged: inquiryDataChanged,
    inquiryResponseCreated: inquiryResponseCreated,
    inquiryResponseDeleted: inquiryResponseDeleted
})

export default rootReducer;