import React, { useState, useEffect } from "react";
import { api } from "../../config/api";
import {
    Space,
    Row,
    Col,
    Typography,
    Breadcrumb,
    Divider,
    Descriptions,
    Spin,
    Tag,
    Card,
    Alert,
    Button
} from "antd";
import { Link, useParams } from "react-router-dom";
import { formatPolishCurrency } from "../../helpers/currency";
import { Helmet } from "react-helmet-async";
import {
    HomeOutlined,
    EuroCircleTwoTone, LineChartOutlined, TeamOutlined, EyeOutlined
} from "@ant-design/icons";
import AvatarDisplay from "../Dashboard/Account/AvatarDisplay";
import NewTransactionForm from "../Transaction/Forms/NewTransactionForm";
import { getAuthToken, isLogged } from "../../helpers/account";
import { Assets } from "../Offer/AssetsPage";
import HelpBox from "../Offer/HelpBox";

const { Title } = Typography;

const MarketplaceAssetPage = () => {
    const [loading, setLoading] = useState(true);
    const [asset, setAsset] = useState({});
    const { assetUuid } = useParams();

    const fetchAssetData = async () => {
        try {
            let apiEndpoint = `${api.host}/assets/${assetUuid}/public`;
            if (isLogged()) {
                apiEndpoint = `${api.host}/assets/${assetUuid}`;
            }
            const assetResponse = await fetch(
                apiEndpoint,
                {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                }
            );
            const assetData = await assetResponse.json();
            setAsset(assetData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching asset data:", error);
        }
    };

    useEffect(() => {
        fetchAssetData();
    }, [assetUuid]);

    return (
        <>
            <Helmet>
                <title>{asset.name}</title>
            </Helmet>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Spin spinning={loading}>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={16} style={{ padding: "15px 15px" }}>
                            <Space direction="vertical">
                                <Title level={2}>{asset.name}</Title>

                                <Breadcrumb
                                    items={[
                                        {
                                            href: "/",
                                            title: <HomeOutlined />,
                                        },
                                        {
                                            href: "/marketplace/assets",
                                            title: "Katalog aktywów",
                                        },
                                        {
                                            title: asset.name,
                                        },
                                    ]}
                                />
                                <img src="/img/svg/16.svg" />
                            </Space>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col md={4}></Col>
                        <Col md={8} style={{ padding: "15px 15px" }}>
                            <Row gutter={12}>
                                <Col md={24}>
                                    <Space size={30} direction="vertical" style={{ width: "100%" }}>
                                        <Space>
                                            {asset.account ? (
                                                <>
                                                    <AvatarDisplay src={asset.account.photo} size={32} account={asset.account} />
                                                    <Space size={1} direction="vertical">
                                                        {asset.account.fullname}
                                                        <div style={{ fontSize: "75%", color: "rgba(0, 0, 0, 0.45)" }}>Sprzedawca</div>
                                                    </Space>
                                                    <Tag>{asset.account.company_name || "Nie podano nazwy firmy"}</Tag>
                                                </>
                                            ) : ""}
                                        </Space>
                                        <Alert
                                            showIcon
                                            message="
Rozpoczęcie transakcji to pierwszy krok, w którym nawiązujesz kontakt ze sprzedawcą. Będziesz mieć możliwość czatu, aby omówić szczegóły oferty. Jeśli nie jesteś zainteresowany, masz możliwość anulowania transakcji.">
                                        </Alert>
                                        <Space>

                                            {isLogged() ? (
                                                <NewTransactionForm
                                                    isForVirtualItem={true}
                                                    initiatorTransactionRoleId={{
                                                        value: 2,
                                                        label: "Nabywca",
                                                    }}
                                                    title={asset.name}
                                                    email={asset.account?.email}
                                                />
                                            ) : (
                                                <><Link to="/transactions">Zaloguj się</Link> aby rozpocząć transakcję</>
                                            )}
                                        </Space>
                                        <img src="/img/svg/19.svg" style={{ width: "100%" }} />
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: asset.description
                                                    ? asset.description.replace(/\n/g, "<br>")
                                                    : "",
                                            }}
                                        ></p>
                                        <Descriptions layout="vertical" bordered>
                                            <Descriptions.Item label="Cena">
                                                <Tag color="gold-inverse"><EuroCircleTwoTone />{" "}{formatPolishCurrency(asset.price)}</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Liczba klientów">
                                                <TeamOutlined /> {asset.customers}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Przychód z ostatnich 12 miesięcy">
                                                <LineChartOutlined /> {formatPolishCurrency(asset.ttm_revenue)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Zysk z ostatnich 12 miesięcy">
                                                <EuroCircleTwoTone /> {formatPolishCurrency(asset.ttm_profit)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Średnia liczba odwiedzających miesięcznie">
                                                <EyeOutlined /> {asset.avg_monthly_visitors}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Segment docelowy">
                                                <Tag color="blue">{asset.target_segment || "Nie podano"}</Tag>
                                            </Descriptions.Item>
                                        </Descriptions>

                                        {asset.photo && (
                                            <Row>
                                                <Col md={4}></Col>
                                                <Col md={16} style={{ textAlign: "center" }}>
                                                    <Card cover={<img alt={asset.name} src={asset.photo} style={{ maxHeight: 400, objectFit: "cover" }} />} />
                                                </Col>
                                                <Col md={4}></Col>
                                            </Row>
                                        )}
                                    </Space>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={8} style={{ padding: "15px 15px" }}>
                            <Space direction="vertical">
                                <img src="/img/svg/18.svg" style={{ width: "100%" }} />
                                <HelpBox />
                            </Space>
                        </Col>
                        <Col md={4}></Col>
                    </Row>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={16} style={{ padding: "0px 15px" }}>
                            <Space direction="vertical">
                                <Title level={2}>
                                    Inne aktywa na sprzedaż
                                </Title>
                                <Card size="small" style={{ backgroundColor: "#1677ff" }}>
                                    <Space direction="horizontal">
                                        Masz działający biznes lub stronę internetową na sprzedaż?{" "}
                                        <Link to="/assets">
                                            <Button type="primary">Wystaw swoją ofertę</Button>
                                        </Link>
                                    </Space>
                                </Card>
                                <Assets limit={100} paginationLimit={100} />
                            </Space>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </Spin>
            </Space>
        </>
    );
};

export default MarketplaceAssetPage;